<!-- eslint-disable -->

<script setup>
import { useMenuStore } from '@/stores/menus';
import { ref, onMounted, watch } from "vue";
import { useGeneralStore } from '@/stores/general';
import { useFooterSectionStore } from '@/stores/footer_sections';
import { version } from '../../../package.json';
import Social from './footer_sections/Social.vue';
import Payments from './footer_sections/Payments.vue';
import PaymentMobile from './footer_sections/PaymentMobile.vue';
import Regulation from './footer_sections/Regulation.vue';
import Contact from './footer_sections/Contact.vue';
import Description from './footer_sections/Description.vue';
import DescriptionMobile from './footer_sections/DescriptionMobile.vue';
import RegulationMobile from './footer_sections/RegulationMobile.vue';
import { computed } from 'vue';

const menuStore = useMenuStore();
const generalStore = useGeneralStore();
const footerSectionStore = useFooterSectionStore();
const is_chat_enabled = process.env.VUE_APP_CHAT_ENABLED == 'true';

const getUrl = (menu_child) => {
	if (!menu_child.click_url && !menu_child.static_page_url) return false
	if (menu_child.click_url == '#/promotions/all') return menu_child.click_url.replace('#', '').replace('/all', '');
	if (menu_child.click_url) return menu_child.click_url.replace('#', '');
	else if (menu_child.static_page_url) return menu_child.static_page_url;
}

const showSocialRegulationSection = computed(() => {
	if (footerSectionStore.getSocialSection || footerSectionStore.getRegulationSection) return true;
	else return false;
});

const showDescriptionContactSection = computed(() => {
	if (footerSectionStore.getDescriptionSection || footerSectionStore.getContactSection) return true;
	else return false;
});

const showPaymentsSection = computed(() => {
	if (footerSectionStore.getPaymentSection) return true;
	else return false;
});

const showDescriptionRegulationSection = computed(() => {
	if (footerSectionStore.getDescriptionSection || footerSectionStore.getRegulationSection) return true;
	else return false;
});

const showContactSection = computed(() => {
	if (footerSectionStore.getContactSection) return true;
	else return false;
});

const flexOrGrid = computed(() => {
	if (footerSectionStore.getDescriptionSection && footerSectionStore.getContactSection) return 'grid';
	else return 'flex';
});

</script>
<template>
	<footer>
		<div class="container-lg">
			<div class="footer-outer">
				<!-- footer column -->
				<template v-if="menuStore.getFooterMenus">
					<div class="footer-inner" v-for="menu in menuStore.getFooterMenus" :key="menu">
						<h6>{{ menu.name }}</h6>
						<div class="footer-menu ">
							<ul>
								<template v-for="menu_child in menu.children" :key="menu_child">
									<li v-if="menu_child.click_url == '/live-chat'" >
										<a v-if="is_chat_enabled" href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});">{{ menu_child.name }}</a>
										<a v-else href="javascript:void(0);">{{ menu_child.name }}</a>
									</li>
									<li v-else>
										<a v-if="getUrl(menu_child)" :href="getUrl(menu_child)">{{ menu_child.name }}</a>
									</li>
								</template>
							</ul>
						</div>
					</div>
				</template>
				<!-- end -->

			</div>

			<div class="footer-desktop">
				<div class="footer-social-regulation" v-if="showSocialRegulationSection">
					<Social> </Social>
					<Regulation> </Regulation>
				</div>
				<div class="footer-descr-contact" v-if="showDescriptionContactSection" :style="{display: flexOrGrid, gap: flexOrGrid == 'flex' ? 'unset' : '4%'}">
					<div class="desc-section">
						<Description></Description>
					</div>

					<div class="footer-contact-section">
						<Contact></Contact>
					</div>
				</div>
				<div class="footer-payments-section" v-if="showPaymentsSection">
					<Payments></Payments>
				</div>
			</div>

			<div class="footer-mobile">
				<div class="mobile-descr-contact" v-if="showContactSection">
					<div class="contact-section">
						<Contact></Contact>
					</div>
				</div>
				<div class="mobile-payments" v-if="showPaymentsSection">
					<Payments></Payments>
				</div>
				<div class="mobile-description" v-if="showDescriptionRegulationSection">
					<div class="desc-section">
						<Description></Description>
						<Regulation style="margin-top: 8px;"> </Regulation>
					</div>
				</div>
			</div>

		</div>
	</footer>
</template>
<style scoped>
.footer-desktop {
	display: none;
}

.footer-mobile {
	display: none;
}

.footer-social-regulation {
	display: flex;
	gap: 10px;
	border-bottom: 1px solid #4E545D;
	padding: 20px 0px;
	justify-content: space-between;
	align-items: center;
}

.footer-descr-contact {
	grid-template-columns: 48% 48%;
	border-bottom: 1px solid #4E545D;
	padding: 20px 0px;
}

.footer-payments-section {
	padding: 20px 0px;
}

.mobile-descr-contact {
	padding: 20px 0px;
	border-bottom: 1px solid #4E545D;
}

.mobile-payments {
	padding: 20px 0px 40px 0px;
	border-bottom: 1px solid #4E545D;
}

.mobile-description {
	padding: 20px 0px;
}

@media screen and (max-width: 767px) {
	.footer-mobile {
		display: block;
	}
}

@media screen and (min-width: 768px) {
	.footer-desktop {
		display: block;
	}
}
</style>
