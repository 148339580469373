import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';
import { MyBets } from './MyBets';
import { BetSlip } from './BetSlip';
import { useMissionsStore } from './missions';

export const useLoginStore = defineStore('login', {
	state: () => ({
		//
	}),
	getters: {
		//
	},
	actions: {
		submitLogin(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/auth/login', payload).then((response) => {
				useCustomerStore().auth_token = response.data.token
				return Promise.resolve(response.data.token);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		logout() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/auth/logout').then((response) => {
				useCustomerStore().emptyCustomer();
				MyBets().emptyMyBets();
				useMissionsStore().emptyMissions();
				BetSlip().resetBetslip();
				location.reload();
				return Promise.resolve(response);
			}).catch((error) => {
				console.log(error)
				return Promise.reject(error.response);

			});
		},
		forgotPassword(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/reset/email', { email: payload }).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		forgotPasswordMobile(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/reset/mobile',  payload ).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		checkPasswordResetToken(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/reset/token', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		checkPasswordResetTokenMobile(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/reset/mobile/token', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		checkMobileToken(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/token/mobile/check', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		resetPassword(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/reset', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		resetPasswordMobile(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/password/mobile/reset', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		skipPasswordReset(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/account/unlock/skip-password-reset', { email: payload }).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		skipPasswordResetMobile(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/security/account/unlock/mobile/skip-password-reset', payload).then((response) => {
				return Promise.resolve(response.data)
			}).catch((error) => {
				return Promise.reject(error.response)
			});
		},
		emailVerification(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/email/verify', payload ).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		resendEmailVerification(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/email/resend', { id: payload } ).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		}
	},
})