<!-- eslint-disable -->
<script setup>
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useCustomerStore } from '@/stores/customer';
import { ref } from 'vue';
import { closeModal, confirmModal } from '@kolirt/vue-modal'
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

const svgs_data = commonSvgs;
const customerStore = useCustomerStore();
const direction = ref(document.dir);
const loading = ref(false)

const options = {
	rewind: true,
	pagination: true,
	arrows: customerStore.getUnreadDropdownNotifications.length > 1 ? true : false,
	perPage: 1,
	direction: direction,
	width: '550px',
	breakpoints: {
		350: {
			width: '320px',
		},
		425: {
			width: '350px',
		},
		475: {
			width: '425px',
		},
		500: {
			width: '475px',
		},
		525: {
			width: '500px',
		},
		550: {
			width: '525px',
		},
	},
}

const closeModalOnClickOutside = () => {
	loading.value = true;
	customerStore.markAsReadDropdownNotifications().then((response) => {
		customerStore.fetchCustomer().then((response) => {
			loading.value = false;
			closeModal();
		})
	})
};

const goToUrl = (notification) => {
	loading.value = true;
	customerStore.markAsReadDropdownNotifications().then((response) => {
		customerStore.fetchCustomer().then((response) => {
			if (notification?.button_link_url) {
				loading.value = false;
				closeModal();
				window.open(notification.button_link_url, '_self');
			}
		})
	})
}

</script>
<template>
	<div class="modal-outer verify-email-popup" ref="modal">
		<Splide :options="options">
			<SplideSlide v-for="(notification, index) in customerStore.getUnreadDropdownNotifications" :key="index">

					<div class="notification-card">
						<button class="close" @click="closeModalOnClickOutside" v-html="svgs_data.popup_cross"></button>


						<div class="modal-header" style="margin-bottom: 25px;">
							<h3>{{ notification.title }}</h3>
						</div>
						<div class="popup-message">
							<p v-html="notification.body"></p>
						</div>
						<div class="password-reset-main" style="margin-top: 25px;">
							<template v-if="notification?.button_link_url">
								<a class="btn btn-secondary" v-if="loading"> <div class="loader-main"></div></a>
								<button v-else class="btn btn-secondary" type="button" @click="goToUrl(notification)">
									{{ notification?.button_name || $t('general.close')}}
								</button>
							</template>
							<template v-else>
								<a class="btn btn-secondary" v-if="loading"> <div class="loader-main"></div></a>
								<button v-else class="btn btn-secondary" type="button" @click="closeModalOnClickOutside">
									{{ notification?.button_name || $t('general.close')}}
								</button>
							</template>
						</div>

					</div>

			</SplideSlide>
		</Splide>
	</div>
</template>
<style scoped>
	.popup-message {
		overflow-wrap: break-word;
		word-wrap: break-word;
		color: #ffffff;
	}

	.notification-card {
		background: #131C27;
		width: 550px;
		display: flex;
		position: relative;
		flex-direction: column;
		min-height: 260px;
		align-items: center;
		justify-content: space-between;
		padding: 30px 20px;
	}

	@media screen and (min-width: 320px) and (max-width: 767px) {
		.notification-card {
			width: 100%;
			padding: 30px 20px;
		}

		.modal-outer .close {
			top: 0px;
			right: 0px;
			background: #131C27;
			padding: 5px;
			border-radius: 50%;
			width: 32px;
		}
	}

	.btn-secondary {
		height: 42px;
		font-size: 15px;
		cursor: pointer;
	}
</style>