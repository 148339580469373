import { defineStore } from 'pinia'
import { useBestlineVideoStore } from './bestline_video';
import { useVirtualCourtStore } from './virtual_court';

export const useLiveCourtStore = defineStore('liveCourt', {
	state: () => ({
		active_court: 'scoreboard',
	}),
	getters: {
		activeCourt(state) {
			return state.active_court;
		},
		hasVideo() {
			const virtualCourtStore = useVirtualCourtStore();
			const bestlineVideoStore = useBestlineVideoStore();

			if (virtualCourtStore.fixture_in_queue && bestlineVideoStore.videoEvents.length > 0) {
				return bestlineVideoStore.videoEvents.some(event => event.id === virtualCourtStore.fixture_in_queue.external_id);			
			}
			
			return false;
		}
	},
	actions: {
		setActiveCourt(payload) {
			this.active_court = payload;
		},
	}
});
