<script setup>
import { ref, watch, onMounted } from 'vue';
import _ from 'lodash';
import { useInitializeStore } from '@/stores/initialize';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveCourtStore } from '@/stores/live_court';
import { useBestlineVideoStore } from '@/stores/bestline_video';
import VirtualCourt from '@/components/live_sports/VirtualCourt.vue'
import VirtualCourtNew from '@/components/live_sports/VirtualCourtNew.vue'
import BestlineVideo from '@/components/live_sports/BestlineVideo.vue';
import { useVirtualCourtStore } from '@/stores/virtual_court';

const initializeStore = useInitializeStore();
const liveSportsStore = useLiveSportsStore();
const liveCourtStore = useLiveCourtStore();
const bestlineVideoStore = useBestlineVideoStore();
const virtualCourtStore = useVirtualCourtStore();
const screenWidth = ref(window.innerWidth);

const selectVirtualLiveFixture = () => {
	liveCourtStore.setActiveCourt('vl');
};

const selectVideoFixture = () => {
	liveCourtStore.setActiveCourt('video');
};

const selectScoreboard = () => {
	liveCourtStore.setActiveCourt('scoreboard');
};

onMounted(() => {
	if (!_.isEmpty(virtualCourtStore.fixture_in_queue)) {
		selectCourt();
	}
});

const selectCourt = () => {
	if (screenWidth.value < 1360) {
		selectScoreboard();
	} else if (bestlineVideoStore.isEnabled == true && liveCourtStore.hasVideo == true) {
		selectVideoFixture();
	} else {
		selectVirtualLiveFixture();
	}
};

watch (() => virtualCourtStore.fixture_in_queue, (new_fixture, old_fixture) => {
	if (new_fixture?.id != old_fixture?.id) {
		selectCourt();
	}
});
</script>
<template>
	<div v-if="screenWidth > 1359 && liveCourtStore.hasVideo && liveSportsStore.hasVirtualLive(liveSportsStore.selected_live_sport_id)" class="button-container">
		<div @click="selectVirtualLiveFixture" class="vl-icon" :class="{'icon-selected': liveCourtStore.activeCourt == 'vl' }">
			<svg class="event-field-svg" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 50 34" xmlns:xlink="http://www.w3.org/1999/xlink">
				<use :xlink:href="liveSportsStore.virtualLiveIcon(virtualCourtStore.fixture_in_queue.sport_id) + '#Layer_x0020_1'" />
			</svg>
		</div>

		<div v-if="liveCourtStore.hasVideo" @click="selectVideoFixture" :class="liveCourtStore.activeCourt == 'video' ? 'video-icon-selected' : ''" class="field-icon-container">
			<svg xmlns="http://www.w3.org/2000/svg" class="video-icon" width="50px" height="34px" viewBox="0 0 50 34" fill="none" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd">
				<rect x="1" y="1" width="48" height="32" rx="4" ry="4" class="border"/>
				<polygon points="19,8.5 34,17 19,25.5" class="play-fill"/>
			</svg>
		</div>
	</div>

	<BestlineVideo v-if="liveCourtStore.activeCourt == 'video'"></BestlineVideo>
	<template v-else-if="liveCourtStore.activeCourt == 'vl'">
		<VirtualCourt v-if="!initializeStore.betcomCourtEnabled"></VirtualCourt>
		<VirtualCourtNew v-else ></VirtualCourtNew>
	</template>
</template>
<style scoped>
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
	gap: 5px;
	margin-top: 20px;
}

.play-fill {
	fill: gray !important;
}

.play-fill-white {
	fill: #F2F2F7;
}

.border {
	fill: none;
	stroke: gray;
	stroke-width: 1;
}

.border-white {
	fill: none;
	stroke: #F2F2F7;
	stroke-width: 1;
}

.video-icon {
	width: 25px;
	height: 17px;
	cursor: pointer;
}

.field-icon-container:hover .play-fill {
	fill: #F2F2F7;
}

.field-icon-container:hover .border {
	stroke: #F2F2F7;
}

.video-icon-selected .play-fill {
	fill: #F2F2F7;
}

.video-icon-selected .border {
	stroke: #F2F2F7;
}

.event-field-svg {
	height: 17px;
	fill: #686767 ;
	cursor: pointer;
}

.icon-selected .event-field-svg {
	fill: #F2F2F7;
}

.event-field-svg:hover {
	fill: #F2F2F7;
}
</style>