<script setup>
import { useRouter } from 'vue-router';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { useLanguagesStore } from '@/stores/languages';
import { ref, onMounted, computed, watch } from 'vue';
import headerIcons from '@/assets/svgs/Header.json'
import _ from 'lodash';

// Initialize Store
const router = useRouter();
const casinoStore = useCasinoStoreV2();
const languagesStore = useLanguagesStore();

// Initialize variables
const header_icons = headerIcons;

// Splide Slider Options
const direction = ref(document.dir);
const options = ref({
	direction: direction.value,
	pagination: false,
	arrows: true,
	autoWidth: true,
	trimSpace: 'move',
});

const search_term = ref(null);

// Creating a debounce function
function debounce(func, delay) {
	let debounceTimer;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => func.apply(context, args), delay);
	};
}

// Set search term with debouncing
const setSearch = debounce((name) => {
	search_term.value = name;

	casinoStore.setSearchTerm(name);

}, 300);

watch(() => casinoStore.getSearchTerm, (value) => {
	search_term.value = value;
});

const filteredCategories = computed(() => {
	return casinoStore.getCategories.filter(category => category.slots_count > 0);
});

const setSelectedCategorySlug = (category_slug) => {
	search_term.value = '';

	let current_selected_category_slug = casinoStore.getSelectedCategorySlug ?? null;

	if (current_selected_category_slug == category_slug) {
		return;
	}

	casinoStore.resetCasinoParameters(category_slug);
	if (category_slug == null) {
		router.push({ name: 'casino' });
	} else if (category_slug == 'favourites') {
		router.push({ name: 'casino-favourites' });
	} else if (category_slug == 'last_played_games') {
		router.push({ name: 'casino-last-played' });
	} else {
		router.push({ name: 'casino-category', params: { category_slug: category_slug } });
	}
}

const categoryName = (category) => {
	const locale = languagesStore.selectedLanguage.locale ?? 'en';

	const categories = casinoStore.getCategories;
	category = _.find(categories, { id: category.id });

	const selected_translation = _.find(category.translations, { locale: locale });

	return selected_translation ? selected_translation.name : category.name;
};

onMounted(() => {
	search_term.value = casinoStore.getSearchTerm;
});
</script>
<template>
	<div class="casino-header-outer">
		<!-- Brands Slider Start -->
		<div class="casino-header-left">
			<div class="tab no-border">
				<Splide v-if="Object.keys(casinoStore.getCategories).length > 0" :options="options" direction:direction>
					<!-- All button -->
					<SplideSlide>
						<button @click="setSelectedCategorySlug(null)" class="tablinks"
							:class="casinoStore.getSelectedCategorySlug == null ? 'active' : ''">
							<div class="content-wrapper">
								<img class="category-icon" src="@/assets/svgs/all.svg" alt="">
								<span class="category-name">{{ $t('general.all_categories') }}</span>
							</div>
						</button>
					</SplideSlide>

					<!-- Favourites button -->
					<SplideSlide v-if="casinoStore.hasFavourites == true">
						<button @click="setSelectedCategorySlug('favourites')" class="tablinks" :class="casinoStore.getActivePage == 'favourites' ? 'active' : ''">
							<div class="content-wrapper">
								<img class="category-icon" src="@/assets/svgs/favourites.svg" alt="">
								<span class="category-name">{{ $t('general.favourites') }}</span>
							</div>
						</button>
					</SplideSlide>

					<!-- Last Played Games button -->
					<SplideSlide v-if="casinoStore.hasLastPlayedGames == true">
						<button @click="setSelectedCategorySlug('last_played_games')" class="tablinks"
							:class="casinoStore.getActivePage == 'last_played_games' ? 'active' : ''">
							<div class="content-wrapper">
								<img class="category-icon" src="@/assets/svgs/lastplayed.svg" alt="">
								<span class="category-name">{{ $t('general.last_played_games') }}</span>
							</div>
						</button>
					</SplideSlide>

					<SplideSlide v-for="category in filteredCategories" :key="category">
						<!-- Brand button -->
						<button @click="setSelectedCategorySlug(category.slug)" class="tablinks"
							:class="casinoStore.getSelectedCategorySlug == category.slug ? 'active' : ''">
							<div class="content-wrapper">
								<img v-if="category.media" class="category-logo"
									:src="casinoStore.getStorageUrl + category.media.path" />
								<svg v-else class="category-icon" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
									viewBox="0 0 18 18">
									<defs>
										<clipPath id="clip-path">
											<path id="path2055" d="M0-682.665H18v18H0Z"
												transform="translate(0 682.665)" />
										</clipPath>
									</defs>
									<g id="Group_526" data-name="Group 526" transform="translate(-465 -583)">
										<g id="g2049" transform="translate(465 583)">
											<g id="g2051">
												<g id="g2053" clip-path="url(#clip-path)">
													<g id="g2059" transform="translate(0.527 0.527)">
														<path id="path2061"
															d="M0-312.86a8.5,8.5,0,0,1,8.473-8.473,8.5,8.5,0,0,1,8.473,8.473,8.5,8.5,0,0,1-8.473,8.473A8.5,8.5,0,0,1,0-312.86Z"
															transform="translate(0 321.332)" fill="none" stroke="#fff"
															stroke-miterlimit="10" stroke-width="0.5" />
													</g>
													<g id="g2063" transform="translate(5.489 5.309)">
														<path id="path2065"
															d="M-129.637,0l1.24,1.985,2.271.566-1.5,1.793.164,2.335-2.17-.877-2.17.877.164-2.335-1.5-1.793,2.271-.566Z"
															transform="translate(133.148)" fill="none" stroke="#fff"
															stroke-miterlimit="10" stroke-width="0.5" />
													</g>
													<g id="g2067" transform="translate(2.672 2.672)">
														<path id="path2069"
															d="M0-233.671A6.328,6.328,0,0,1,6.328-240a6.328,6.328,0,0,1,6.328,6.328,6.328,6.328,0,0,1-6.328,6.328A6.328,6.328,0,0,1,0-233.671Z"
															transform="translate(0 239.999)" fill="none" stroke="#fff"
															stroke-miterlimit="10" stroke-width="0.5" />
													</g>
												</g>
											</g>
										</g>
									</g>
								</svg>
								<span class="category-name">{{ categoryName(category) }}</span>
							</div>
						</button>

					</SplideSlide>
				</Splide>
			</div>
		</div>
		<!-- Brands Slider End -->

		<!-- Search Start -->
		<div class="casino-header-right">
			<div class="search-inner">
				<!-- <form> -->
				<input :value="search_term" @input="setSearch($event.target.value)" type="search"
					:placeholder="$t('general.search_for_games')">
				<button type="button">
					<object v-html="header_icons.search"></object>
				</button>
				<!-- </form> -->
			</div>
		</div>
		<!-- Search End -->
	</div>
</template>
<style scoped>
.casino-tabing-header .casino-header-outer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.casino-tabing-header .casino-header-outer .casino-header-left {
	width: 63%;
	position: relative;
}

.casino-tabing-header .casino-header-outer .casino-header-right {
	width: 35%;
	position: relative;
}

@media (max-width: 768px) {
	.casino-tabing-header .casino-header-outer {
		flex-direction: column;
		align-items: flex-start;
	}

	.casino-tabing-header .casino-header-outer .casino-header-left,
	.casino-tabing-header .casino-header-outer .casino-header-right {
		width: 100%;
	}
}

html[dir="rtl"] .casino-tabing-header .casino-header-outer .search-inner button {
	right: unset !important;
	left: 10px !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.casino-tabing-header .casino-header-outer .search-inner {
		width: auto;
		margin-left: 24px;
		margin-right: 24px;
	}
}

.splide__slide {
	max-width: 180px;
}

.casino-tabing-header .casino-header-outer .casino-header-left .splide .splide__track .splide__list li:first-child .tablinks {
	display: unset !important;
}

.tablinks {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 4px;
	border: none;
	background: transparent;
	text-align: left;
}

.content-wrapper {
	display: flex;
	align-items: center;
}

.category-logo,
.category-icon {
	margin-right: 8px;
	height: 24px;
}

.last-played-icon {
	margin-right: 10px;
	height: 20px;
	fill: #fff;
	opacity: 0.8;
}

.category-name {
	display: block;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.casino-tabing-header .casino-header-outer .casino-header-left .splide .splide__track .splide__list {
	gap: 7px !important;
}

.st0 {
	fill-rule: evenodd;
	clip-rule: evenodd;
}

:deep(.splide__arrow) {
    position: absolute !important;
}

:deep(.splide__arrow:disabled) {
    display: none !important;
}

:deep(.splide__arrow--prev) {
    display: unset !important;
}

:deep(.splide__arrow--next) {
    display: unset !important;
}

:deep(.casino-game-area .casino-top-filters .casino-game_tab-outer .casino-games-tab .splide .splide__arrows .splide__arrow--next) {
    display: unset !important;
}

:deep(.splide__arrows .splide__arrow--next svg) {
    display: unset;
}

:deep(.splide__arrows .splide__arrow--prev svg) {
    display: unset;
}

:deep(.splide__arrow) {
    background-color: #131C27 !important;
    color: #878A90 !important;
    height: 26px !important;
    width: 26px !important;
    border: 0.5px solid #878A90 !important;
}

:deep(.splide__arrow:hover),
:deep(.splide__arrow:focus) {
    color: #ffffff !important;
    background-color: #1e2740 !important;
    box-shadow: 0 0 1px #ffffff;
}

:deep(.splide__arrow svg) {
    fill: currentColor !important;
    width: 10px !important;
    height: 12px !important;
}

html[dir="rtl"] .category-logo {
    margin-left: 8px !important;
}

html[dir="rtl"] .category-icon {
    margin-left: 8px !important;
}


:deep(.splide__arrow--next) {
	right: 0 !important;
}
</style>