<script setup>
/* eslint-disable */
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { closeModal } from '@kolirt/vue-modal';
import { useRuntimePopupStore } from '@/stores/runtime_popup';
import { useCustomerStore } from '@/stores/customer';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import Loader from '@/components/Common/Loader.vue';

const runtimePopupStore = useRuntimePopupStore();
const customerStore = useCustomerStore();
const svg_data = ref(commonSvgs);
const loading = ref(false);

const props = defineProps({
	runtime_popup: {
		type: Object,
		required: true
	}
})

const closePopup = () => {
	if(!customerStore.isLoggedIn && (props.runtime_popup.show_to == 'guest' || props.runtime_popup.show_to == 'both')) {
		runtimePopupStore.dismissUnauthenticated(props.runtime_popup.id, props.runtime_popup.repeat);
		closeModal();
	} else if(customerStore.isLoggedIn && (props.runtime_popup.show_to == 'logged_in' || props.runtime_popup.show_to == 'both')) {
		runtimePopupStore.dismissAuthenticated(props.runtime_popup.id, props.runtime_popup.repeat).then((response) => {
			closeModal();
		})
	}
}

const btnClose = () => {
	if (props.runtime_popup.button_url) {
		if(!customerStore.isLoggedIn && (props.runtime_popup.show_to == 'guest' || props.runtime_popup.show_to == 'both')) {
			runtimePopupStore.dismissUnauthenticated(props.runtime_popup.id, props.runtime_popup.repeat);
			closeModal();
			window.open(props.runtime_popup.button_url, '_self');
		} else if(customerStore.isLoggedIn && (props.runtime_popup.show_to == 'logged_in' || props.runtime_popup.show_to == 'both')) {
			runtimePopupStore.dismissAuthenticated(props.runtime_popup.id, props.runtime_popup.repeat).then((response) => {
				closeModal();
				window.open(props.runtime_popup.button_url, '_self');
			});
		};
	} else {
		closePopup();
	}
}

const clickOutsideModal = (event) => {

	const modal = document.getElementById('popup-modal');

	if (modal && !modal.contains(event.target)) {
		closePopup();
	}
};

onBeforeMount(() => {
	runtimePopupStore.is_popup_open = true;
})

onMounted(() => {
	document.addEventListener('click', clickOutsideModal);
	loading.value = false;
})

onUnmounted(() => {
	runtimePopupStore.is_popup_open = false;
	document.removeEventListener('click', clickOutsideModal);
})

</script>
<template>
	<div class="register-panel login-pannel" id="popup-modal">
		<div class="top-info-bar">
			<button class="close" @click="closePopup()"  v-html="svg_data.popup_cross"></button>
		</div>

		<div class="content">
			<div v-if="loading">
				<Loader/>
			</div>
			<div v-else class="bets-container">
				<img v-if="props.runtime_popup.media" :src="props.runtime_popup.media" class="img"/>

				<div class="text-container">
					<div class="title" >
						{{ props.runtime_popup.title }}
					</div>

					<div class="description" v-html="props.runtime_popup.description"></div>

				</div>
			</div>
		</div>

		<div v-if="!loading" class="bottom-info-bar">
			<div class="bottom-container">
				<button v-if="!runtimePopupStore.loading" class="btn-small btn-secondary betslip-button" @click="btnClose()">
					{{ props.runtime_popup.button_text ? props.runtime_popup.button_text : $t('general.close') }}
				</button>
				<button v-else class="btn-small btn-secondary betslip-button flexy-box">
					<div class="loader-main"></div>
				</button>
			</div>
		</div>
	</div>
</template>
<style scoped>
.register-panel {
	width: 40% !important;
	height: auto;
	max-height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 8px;
	overflow-y: hidden;
}

.top-info-bar {
	margin-top: 10px;
	padding-bottom: 10px;
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid #4E545D;
}

.bottom-info-bar {
	display: block;
	border-top: 1px solid #4E545D;
	margin-top: 10px;
}

.close {
	cursor: pointer;
	padding-right: 10px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
	.register-panel {
		width: 100% !important;
		height: auto;
	}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
	.register-panel {
		width: 60% !important;
		height: auto;
	}
}

.bottom-container {
	padding: 10px;
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
}

.content {
	height: 100%;
	overflow-y: auto;
}

.bets-container {
	display: block;
	height: 100%;
}

.btn-small {
	min-width: 150px;
}

.flexy-box {
	display: flex;
	justify-content: center;
}

.button-container-mobile {
	text-align: center;
}

.close-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.img-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;
}

.img {
	display: block;
	margin: 0 auto;
	height: 250px;
	width: 100%;
	object-fit: cover;
}

.text-container {
	padding: 10px 20px 0px 20px;
	overflow-y: auto;
}

.title {
	font-size: 25px;
	text-align: center;
	color: #fff;
	font-weight: 600;
}

.description {
	margin-top: 10px;
}

:deep(.description p),
:deep(.description a),
:deep(.description h1),
:deep(.description h2),
:deep(.description h3),
:deep(.description h4),
:deep(.description h5),
:deep(.description h6) {
  color: #fff;
}
</style>