import { defineStore } from 'pinia'

export const useBestlineVideoStore = defineStore('bestlineVideo', {
	state: () => ({
		video_events: {},
		stream_url: '',
	}),
	getters: {
		isEnabled() {
			return process.env.VUE_APP_BESTLINE_VIDEO_ENABLED == 'true' ? true : false;
		},
		videoEvents(state) {
			return state.video_events;
		},
		streamUrl(state) {
			return state.stream_url;
		},
	},
	actions: {
		fetchVideoEvents() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/video/inplay').then((response) => {
				const video_events = response.data.data || [];

				this.video_events = video_events
				return Promise.resolve(video_events);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchVideoLink(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/video/stream-url', payload).then((response) => {
				this.stream_url = response.data.stream_link

				return Promise.resolve(response.data.stream_link);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setVideoEvents(payload) {
			this.video_events = payload
		}
	}
})
