<script setup>
/* eslint-disable */
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useCompetitionsStore } from '@/stores/competitions';
import { usePreSportsStore } from '@/stores/pre_sports';

const competitions_opened = ref(false);
const router = useRouter();
const competitionsStore = useCompetitionsStore();
const preSportsStore = usePreSportsStore();
const show_leagues = ref(true);

const goToCompetition = (competition) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: competition.sport_id, region_id: competition.region_id, competition_id: competition.id } } )
}

onMounted(() => {
	if (router.currentRoute.value.params.sport_id != '') {
		competitionsStore.fetchTopCompetitionsSport(router.currentRoute.value.params.sport_id);
		console.log()
	}
})

watch(() => preSportsStore.selected_pre_sport_id, (newSportId, oldSportId) => {
	if (newSportId > 0) {
		// Fetch regions of this sport
		competitionsStore.fetchTopCompetitionsSport(newSportId);
	}
})
</script>
<template>
	<div class="top-leagues-mobile" v-if="router.currentRoute.value.name == 'pre-sports-coupons' && competitionsStore.topCompetitionsSport.length > 0">
		<div class="title-container" @click="show_leagues = !show_leagues">
			<h6 class="title">{{ $t('general.top_leagues') }}</h6>
			<img :class="{ 'rotated': show_leagues }" src="@/assets/images/arrow-select.svg" width="16" alt="">
		</div>
		<div class="body-container" v-show="show_leagues">
			<div class="top-leagues-box" v-for="competition in competitionsStore.topCompetitionsSport" :key="competition.id" @click="goToCompetition(competition)">
				<div class="country-flag">
					<img :src="'/assets' + competition.region_icon" alt="flag-country" >
				</div>
				<div class="country-name">
					<h3>{{ competition.name }}</h3>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.top-leagues-mobile {
	padding: 4px 12px;
}
.title-container {
	display: grid;
	grid-template-columns: 1fr 24px;
	align-items: center;
	padding-bottom: 8px;
}
.title {
	font-size: 14px;
	color: #63A246;
}
.body-container{
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}
.top-leagues-box {
	display: grid;
	grid-template-columns: 24px 1fr;
	align-items: center;
	gap: 8px;
	padding: 10px;
	border: 1px solid #4E545D;
	border-radius: 6px;
	min-width: 100%;
}
.rotated {
	transform: rotate(180deg);
}
.country-flag {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	margin-top: 3px;
}
.country-flag img{
	width: 18px;
	height: 28px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}
.country-name {
	overflow: hidden;
}
.country-name h3{
	font-size: 13px;
	color: #ffffff;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
	.body-container{
		grid-template-columns: 1fr;
	}
	.top-leagues-mobile {
		padding: 4px 12px 12px 12px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.body-container{
		grid-template-columns: 1fr 1fr 1fr;
	}
	.top-leagues-mobile {
		padding: 0px 12px 12px 12px;
	}
}

@media screen and (min-width: 992px) {
	.top-leagues-mobile{
		display: none;
	}
}


</style>