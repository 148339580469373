<script setup>
/* eslint-disable */
import { onMounted, onBeforeUnmount, watch, ref } from "vue";
import { useRouter } from 'vue-router';
import { useSocketStore } from '@/stores/socket';
import { useMarketsStore } from '@/stores/markets';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useVirtualCourtStore } from '@/stores/virtual_court';
import { useFavouritesStore } from '@/stores/favourites';
import LiveFixtureDoubleMarket from '@/components/live_sports/LiveFixtureDoubleMarket.vue';
import LeftSidebarCompetition from '@/components/live_sports/left_sidebar/LeftSidebarCompetition.vue';
import LeftSidebarFavourites from '@/components/live_sports/left_sidebar/LeftSidebarFavourites.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { usePreSportsStore } from '@/stores/pre_sports';

const svg_data = ref(commonSvgs);

const competitions_opened = ref(true);

const router = useRouter();
const socketStore = useSocketStore();
const marketsStore = useMarketsStore();
const liveSportsStore = useLiveSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const favouritesStore = useFavouritesStore();
const preSportsStore = usePreSportsStore();

const primary_overview_market = ref(null);


socketStore.getSocket.on('liveSportOverview', (data) => {
	liveSportOverviewStore.setOverview(data);

	if (router.currentRoute.value.name != 'live-sports-overview') return;
})

socketStore.getSocket.on('liveSportOverviewUpdate', (data) => {
	liveSportOverviewStore.updateOverview(data);
})

onMounted(() => {
	if (liveSportsStore.selected_live_sport_id) {
		socketStore.getSocket.emit('join-liveSportOverview', liveSportsStore.selected_live_sport_id);
		let sport_overview_markets = marketsStore.sportOverviewMarkets(liveSportsStore.selected_live_sport_id);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];
		}
	}
	if (preSportsStore.selected_pre_sport_id) {
		socketStore.getSocket.emit('join-liveSportOverview', preSportsStore.selected_pre_sport_id);
		let sport_overview_markets = marketsStore.sportOverviewMarkets(preSportsStore.selected_pre_sport_id);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];
		}
	}
});

onBeforeUnmount(() => {
	socketStore.getSocket.emit('leave-liveSportOverview');
	liveSportOverviewStore.emptyLiveSportsOverview();
})

watch(() => liveSportsStore.selected_live_sport_id, (newSportId, oldSportId) => {
	if (newSportId > 0) {
		liveSportOverviewStore.emptyLiveSportsOverview();

		// Find first overview market of new selected live sport id
		socketStore.getSocket.emit('join-liveSportOverview', newSportId);

		let sport_overview_markets = marketsStore.sportOverviewMarkets(newSportId);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];
		}

	} else if (newSportId == null) {
		socketStore.getSocket.emit('leave-liveSportOverview');
		liveSportOverviewStore.emptyLiveSportsOverview();
	}
})
watch(() => preSportsStore.selected_pre_sport_id, (newSportId, oldSportId) => {
	if (newSportId > 0) {
		liveSportOverviewStore.emptyLiveSportsOverview();

		// Find first overview market of new selected live sport id
		socketStore.getSocket.emit('join-liveSportOverview', newSportId);

		let sport_overview_markets = marketsStore.sportOverviewMarkets(newSportId);
		if (sport_overview_markets.length > 1) {
			primary_overview_market.value = sport_overview_markets[0];
		}

	} else if (newSportId == null) {
		socketStore.getSocket.emit('leave-liveSportOverview');
		liveSportOverviewStore.emptyLiveSportsOverview();
	}
})

watch(() => liveSportOverviewStore.overviewFixtures, (newOverview, oldOverview) => {
	if (_.isEmpty(oldOverview) && !_.isEmpty(newOverview)) {
		setTimeout(() => {
			var scrollableElement = document.querySelector('#left-sidebar-container');

			// Get the target element and its offset
			var targetElement = document.getElementById('thechosenone');
			if (!targetElement) return;

			var targetOffset = targetElement.parentElement.offsetTop - 190;

			// Scroll to the target position
			scrollableElement.scrollTo({
				top: targetOffset,
				behavior: 'auto'
			});
		}, 200);
	}
})

</script>
<template>
	<div class="accordion-outer" id="left-sidebar-container">
		<div class="accordion">
			<div class="container">
				<div> 
					<p v-if="liveSportsStore.getSelectedLiveSportName" style="font-size: 14px; color: #fff; font-weight: 300;">
						{{ liveSportsStore.getSelectedLiveSportName }} ({{ liveSportOverviewStore.overviewFixturesCount}})
					</p>
					<p v-else-if=" preSportsStore.selectedPreSportName" style="font-size: 14px; color: #fff; font-weight: 300;">
						{{ preSportsStore.selectedPreSportName }} ({{ liveSportOverviewStore.overviewFixturesCount}})
					</p>
				</div>
				<span class="toggle-markets">
					<div @click="competitions_opened = !competitions_opened" class="markets-toggle-fill">
						<svg v-if="!competitions_opened" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
						</svg>
					</div>
				</span>
			</div>
			<!-- Favorite Tab Start -->
			<LeftSidebarFavourites v-if="Object.keys(favouritesStore.liveLeftSidebarFavourites).length >0" :competitions_opened="competitions_opened"/>

			<template v-for="(competition, index) in liveSportOverviewStore.overviewFixtures" :key="index">
				<LeftSidebarCompetition :competition="competition" :primary_overview_market="primary_overview_market" :competitions_opened="competitions_opened"/>
			</template>
		</div>
	</div>
</template>
<style scoped>
.container {
	display: grid;
	grid-template-columns: 90% 10%;
}

@media screen and (min-width: 1281px) and (max-width: 1359px) {
	.sports-main .eventview-pannel .sports-outer-left .sports-left-sidebar .accordion-outer {
		background: transparent !important;
		padding: 0 0 0 0;
	}
}

@media screen and (min-width: 768px) and (max-width: 1359px) {
	.sports-main .sports-main-outer .sports-left-sidebar .accordion-outer {
        max-height: 900px;
    }
}

</style>