<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, inject, watch, ref, nextTick } from 'vue';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useFixtureStore } from '@/stores/fixture';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';
import SoccerScoreboard from '@/components/LiveSports/SoccerScoreboard.vue';
import PistolShootingScoreboard from '@/components/LiveSports/ScoreboardGrid/PistolShooting.vue';
import ArcheryShootingScoreboard from '@/components/LiveSports/ScoreboardGrid/ArcheryShooting.vue';
import BasketballScoreboard from '@/components/LiveSports/ScoreboardGrid/Basketball.vue';
import SetgamesScoreboard from '@/components/LiveSports/SetgamesScoreboard.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import headerIcons from '@/assets/svgs/Header.json'
import FixtureBookMarket from '@/components/Common/FixtureBookMarket.vue';
import LiveCourt from '@/components/live_sports/LiveCourt.vue';
import { useLiveCourtStore } from '@/stores/live_court';

const markets_closed = ref(false);
const show_scroll_to_top_button = ref(false);
const header_icons = ref(headerIcons);
const liveSportsStore = useLiveSportsStore();
const fixtureStore = useFixtureStore();
const liveCourtStore = useLiveCourtStore();
const screenWidth = ref(window.innerWidth);
const direction = ref(document.dir);
const splideRef = ref(null);
const options = ref({
	direction: direction.value,
	pagination: false,
	// arrows: false,
	// focus: 'center',
	autoWidth: true,
	gap: 20,
	trimSpace: 'move'
});

const checkArrowsVisibility = () => {
	screenWidth.value = window.innerWidth;

	if (splideRef.value && splideRef.value.splide) {
		const slider = splideRef.value.splide;
		let totalWidth = 0;
		slider.Components.Elements.slides.forEach(slide => {
			const marginRight = parseFloat(window.getComputedStyle(slide).marginRight);
			totalWidth += slide.offsetWidth + marginRight;
		});

		const sliderWidth = slider.Components.Elements.track.clientWidth;

		// Update arrow visibility
		slider.options = {
			...slider.options,
			arrows: totalWidth > sliderWidth
		};

		slider.refresh();
	}
};

watch(() => fixtureStore.marketGroups, (newValue, oldValue) => {
	if (newValue.length > 0) {
		nextTick().then(() => {
			checkArrowsVisibility();
		});
	}
});

const changeMarketGroup = (market_group) => {
	fixtureStore.setSelectedMarketGroup(market_group);
};

onMounted(() => {
	window.scrollTo(0, 0);

	nextTick().then(() => {
		checkArrowsVisibility();
	});
	window.addEventListener('resize', checkArrowsVisibility);

	window.addEventListener('scroll', handleScroll);
	const container = document.querySelector('.live-tab-info');

	if (container) {
		container.addEventListener('scroll', handleElementScroll);
	}

});

const scrollToTop = () => {
	const container = document.querySelector('.live-tab-info');
	window.scrollTo({ top: 0 });

	if (container) {
		container.scrollTo({ top: 0 });
	}
};

const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};

const handleElementScroll = () => {
	const container = document.querySelector('.live-tab-info');

	if (container) {
		const scrollTop = container.scrollTop;
		show_scroll_to_top_button.value = scrollTop > 100;
	}
};

onUnmounted(() => {
	window.removeEventListener('resize', checkArrowsVisibility);
});
</script>
<template>
	<LoaderComponent v-if="fixtureStore.fixture_loading"/>
	<div class="live-sports-center" v-else>
		<div class="eventview-virtual-tabs">
			<div v-if="liveCourtStore.hasVideo" class="virtual-tab-inner field-icon-container" :class="{'active': liveCourtStore.active_court == 'video'}" @click="liveCourtStore.setActiveCourt('video')">
				<svg xmlns="http://www.w3.org/2000/svg" class="video-icon" width="50px" height="34px" viewBox="0 0 50 34" fill="none" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd">
					<rect x="1" y="1" width="48" height="32" rx="4" ry="4" class="border-white"/>
					<polygon points="19,8.5 34,17 19,25.5" class="play-fill-white"/>
				</svg>
			</div>
			<div class="virtual-tab-inner" :class="{'active': liveCourtStore.active_court == 'vl'}" @click="liveCourtStore.setActiveCourt('vl')">
				<svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.988" viewBox="0 0 19 13.988">
					<g id="Group_934" data-name="Group 934" transform="translate(-20.047 -558.794)">
					<g id="Group_933" data-name="Group 933" transform="translate(20.547 559.294)">
						<path id="path2506" d="M566.221-442h-2.9v-5.833h2.9Z" transform="translate(-548.221 451.406)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						<path id="path2508" d="M16.237-442h-2.9v-5.833h2.9Z" transform="translate(-13.333 451.406)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						<path id="path2510" d="M31.333-565.013h-18V-578h18Z" transform="translate(-13.333 578.001)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						<g id="g2512" transform="translate(6.226 3.719)">
						<path id="path2514" d="M-197.06-98.531a2.774,2.774,0,0,1-2.774,2.774,2.774,2.774,0,0,1-2.774-2.774,2.774,2.774,0,0,1,2.774-2.774A2.774,2.774,0,0,1-197.06-98.531Z" transform="translate(202.609 101.305)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						</g>
						<g id="g2516" transform="translate(9 0.083)">
						<path id="path2518" d="M0,0V12.822" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
						</g>
					</g>
					</g>
				</svg>
			</div>

			<div class="virtual-tab-inner" :class="{'active': liveCourtStore.active_court == 'scoreboard'}" @click="liveCourtStore.setActiveCourt('scoreboard')">
				<svg xmlns="http://www.w3.org/2000/svg" width="17.945" height="18" viewBox="0 0 17.945 18">
					<g id="Group_1734" data-name="Group 1734" transform="translate(-281.203 -149.412)">
					<g id="Group_1733" data-name="Group 1733" transform="translate(281.703 149.412)">
						<path id="path3134" d="M100-301.333h4.044v2.545H100Z" transform="translate(-97.987 311.459)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
						<path id="path3144" d="M100-120.666h3.091v2.593H100Z" transform="translate(-97.939 135.558)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
						<path id="path3146" d="M100-662.665h14.884v2.593H100Z" transform="translate(-97.939 663.18)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
						<g id="g3148" transform="translate(0 0)">
						<path id="path3150" d="M0-664.665v-18" transform="translate(0 682.665)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
						</g>
						<path id="path3152" d="M100-482h10.178v2.545H100Z" transform="translate(-97.987 487.328)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
					</g>
					</g>
				</svg>
			</div>
		</div>

		<template v-if="screenWidth > 1359">
			<SoccerScoreboard v-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Soccer'"/>
			<PistolShootingScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Pistol Shooting'"/>
			<ArcheryShootingScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Archery Shooting'"/>
			<BasketballScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Basketball'"/>
			<SetgamesScoreboard v-else/>
		</template>
		<template v-else>
			<template v-if="liveCourtStore.active_court == 'scoreboard'">
				<SoccerScoreboard v-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Soccer'"/>
				<PistolShootingScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Pistol Shooting'"/>
				<ArcheryShootingScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Archery Shooting'"/>
				<BasketballScoreboard v-else-if="liveSportsStore.liveSportName(fixtureStore.fixtureBook.sport_id) == 'Basketball'"/>
				<SetgamesScoreboard v-else/>
			</template>
			<LiveCourt></LiveCourt>
		</template>

		<!----- info tab start ------>
		<div class="live-bet-dashboard">
			<div class="top-search-bar">
				<div></div>
				<div class="header-search-field">
					<div class="header-search-inner">
						<input type="search" :placeholder="$t('general.search_for_specific_markets')" v-model="fixtureStore.market_search">
						<button type="submit">
							<div>
								<object v-html="header_icons.search"></object>
							</div>
						</button>
					</div>
				</div>
			</div>

			<div class="all_detail">
				<div class="slider-grid">
					<Splide ref="splideRef" :options="options" direction:direction>
						<SplideSlide v-for="(market_group) in fixtureStore.sortedMarketGroups(fixtureStore.marketGroups)" :key="market_group">
							<button class="tablinks" :class="{ 'active': market_group == fixtureStore.selectedMarketGroup }"
								@click="changeMarketGroup(market_group)">{{ market_group }} ({{fixtureStore.marketGroups[market_group]}})</button>
						</SplideSlide>
					</Splide>
				</div>
				<span class="toggle-markets">
					<div @click="markets_closed = !markets_closed" class="markets-toggle-fill">
						<svg v-if="!markets_closed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_close.svg#markets_close"></use>
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" svg-inline="" title="Toggle" role="presentation" focusable="false" xmlns:xlink="http://www.w3.org/1999/xlink" class="markets-toggle-svg">
							<use xlink:href="/assets/icons/markets_open.svg#markets_open"></use>
						</svg>
					</div>
				</span>
			</div>

			<div class="live-tab-info" v-if="fixtureStore.fixtureBook && fixtureStore.fixtureBook.markets && fixtureStore.fixtureBook.markets.length > 0">
				<div id="all_event" class="eventview-center-panel">
					<div class="live-info-outer" style="display:unset;">
						<FixtureBookMarket v-for="market in fixtureStore.fixtureBook.markets" :key="market.id" :market="market" :markets_closed="markets_closed"/>
					</div>
				</div>

			</div>
			<div class="live-tab-info" v-else>
				<p class="no-bet-message"> {{ $t('general.no_markets') }}</p>
			</div>
			<!----- info tab start ------>

		</div>
		<!-- center grid end -->

	</div>
	<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
		<i class="fas fa-arrow-up"></i>
	</button>
</template>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 991px) {
	.live-tab-info {
		max-height: unset!important;
		position: unset!important;
		min-height:unset!important;
		height:unset!important;
	}
}
@media screen and (min-width: 767px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
.all_detail {
	margin-bottom: 22px;
}

:deep(.splide__arrow:disabled) {
	display: none;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next:after ) {
	rotate: 180deg;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--next) {
	left: 0px !important;
}

html[dir="rtl"] .slider-grid :deep(.splide__arrows .splide__arrow--prev:after ) {
	rotate: 180deg;
}

.slider-grid {
	display: grid;
	grid-auto-columns: minmax(142px, auto);
	grid-gap: 15px;
	grid-auto-flow: column;
	padding: 0px;
	width: 100%;
	height: 100%;
	transform: translateX(0px);
	transition: all 1s ease;
	border-bottom: 1px solid #707070;
}

.slider-grid .tablinks {
	color: #878A90;
	font-size: 13px;
	padding-bottom: 6px;
	text-transform: capitalize;
	cursor: pointer;
}

.slider-grid .active {
	color: #fff;
	position: relative;
}

.all_detail {
	position: relative;
	display: grid;
	grid-template-columns: 97% 3%;
	overflow: hidden;
}

.tab {
	overflow-y: hidden;
	overflow-x: overlay;
}


::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.all_detail::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}

.tablinks {
	white-space: nowrap;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.all_detail {
		grid-template-columns: 93% 5%;
	}
	.sports-main .eventview-pannel .sports-center-content .live-tab-info {
		min-height: 100%;
		max-height: unset;
	}
}

.live-info-outer {
	gap: 0px !important;
}

html[dir="rtl"] .top-search-bar .header-search-field button {
	left: 14px;
	right: unset;
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #63A246;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-main .eventview-pannel .sports-center-content .live-tab-info {
		min-height: 100%;
		max-height: unset;
		height: 100vh;
	}

	.sports-main .sports-center-content .eventview-center-panel .live-info-outer {
		border: none;
		overflow-y: scroll;
	}
}

.live-tab-info {
	scrollbar-width: none !important;
}

@media screen and (max-width: 991px) {
	.eventview-virtual-tabs {
		display: none !important;
	}
}

@media screen and (min-width: 1360px) {
	.eventview-virtual-tabs {
		display: none !important;
	}
}

.eventview-virtual-tabs {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 8px;
}

.eventview-virtual-tabs .virtual-tab-inner {
	border: 1px solid #4e545d;
	border-radius: 3px;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.eventview-virtual-tabs .virtual-tab-inner.active {
    background: #878a90;
}

.video-icon {
	width: 25px;
	height: 17px;
	cursor: pointer;
}

.play-fill-white {
	fill: #F2F2F7;
}

.border-white {
	fill: none;
	stroke: #F2F2F7;
	stroke-width: 1;
}
</style>