import { defineStore } from 'pinia';
import _ from 'lodash';
import moment from 'moment';
import { useCustomerStore } from './customer';

export const useRuntimePopupStore = defineStore('runtimePopup', {
	state: () => ({
		runtime_popups: [],
		dismissed_runtime_popups: {},
		loading: false,
		is_popup_open: false,
	}),
	getters: {
		getUnauthenticated(state) {
			return _.filter(state.runtime_popups, popup =>
				popup.show_to === 'guest' || popup.show_to === 'both'
			);
		},
		getAuthenticated(state) {
			return _.filter(state.runtime_popups, popup =>
				popup.show_to === 'logged_in' || popup.show_to === 'both'
			);
		},
	},
	actions: {
		fetchUnauthenticated() {
			this.loading = true;
			return global.axios.get(process.env.VUE_APP_API_URL + '/runtime-popups/fetch/guest').then((response) => {
				this.runtime_popups = response.data
				this.loading = false

				return Promise.resolve(response.data.data)
			}).catch((error) => {
				this.loading = false

				return Promise.reject(error);
			})
		},
		fetchAuthenticated() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/runtime-popups/fetch/customer').then((response) => {
				this.runtime_popups = response.data

				return Promise.resolve(response.data.data)
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		dismissUnauthenticated(runtime_popup_id, repeat) {
			this.loading = true;

			const now = moment().valueOf();

			if (repeat == 'once') this.dismissed_runtime_popups[runtime_popup_id] = null;
			else if (repeat == 'per_day') this.dismissed_runtime_popups[runtime_popup_id] = now + (24 * 60 * 60 * 1000);

			this.loading = false
		},
		dismissAuthenticated(runtime_popup_id, repeat) {
			this.loading = true;
			return global.axios.post(process.env.VUE_APP_API_URL + '/runtime-popups/read', { runtime_popup_id: runtime_popup_id }).then((response) => {
				this.dismissUnauthenticated(runtime_popup_id, repeat)
				this.loading = false

				return Promise.resolve(response);
			}).catch((error) => {
				this.loading = false

				return Promise.reject(error.response);
			})
		},
		checkRouteForPopup(path) {
			const customerStore = useCustomerStore();
			const popups_to_check = customerStore.isLoggedIn ? this.getAuthenticated : this.getUnauthenticated;

			const matching_popup = _.find(popups_to_check, popup => {
				// 1. Check if the route matches any of the popup's pages
				let route_match = false;
				if (popup.page.length == 0) route_match = false;
				if (path === '/' && _.some(popup.page, page => page.click_url === 'landing')) route_match = true
				else route_match = _.some(popup.page, page => page.click_url === null) || _.some(popup.page, page => path.includes(page.click_url));

				// 2. Check if the popup is not permanently dismissed
				const is_permanently_dismissed = this.dismissed_runtime_popups[popup.id] === 'null' || this.dismissed_runtime_popups[popup.id] === null;

				// 3. If repeat is 'once', check if it's already dismissed (regardless of time)
				const is_dismissed_once = popup.repeat === 'once' && this.dismissed_runtime_popups[popup.id];

				// 4. If repeat is 'per_day', check if it was dismissed within the last 24 hours
				const was_dismissed_today = popup.repeat === 'per_day' &&
					this.dismissed_runtime_popups[popup.id] &&
					this.dismissed_runtime_popups[popup.id] > moment().subtract(1, 'days').valueOf()

				return route_match && !is_permanently_dismissed && !is_dismissed_once && !was_dismissed_today;
			});

			return matching_popup || null;
		}

	},
	persist: {
		storage: localStorage,
		paths: ['dismissed_runtime_popups'],
	},
})