<script setup>
import { onMounted, ref } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import { useAccountDetailsStore } from '@/stores/account_details';
import { closeModal } from '@kolirt/vue-modal';
import commonSvgs from '@/assets/svgs/commonSvgs.json';

const svg_data = commonSvgs;

const customerStore = useCustomerStore();
const accountDetailsStore = useAccountDetailsStore();
const betslip_changes = ref('');

// Function to update betslip preferences
const toggleBetslipChangesPreferences = (betslip_changes_option) => {
	const payload = { betslip_changes: 'always_ask'};
	if (betslip_changes.value != betslip_changes_option) payload.betslip_changes = betslip_changes_option;

	accountDetailsStore.updateCustomerOdds(payload).then( () => {
		betslip_changes.value = payload.betslip_changes
	})
}

onMounted(() => {
	if (customerStore.isLoggedIn) {
		betslip_changes.value = customerStore.getCustomer.customer_settings.betslip_changes;
	} else {
		betslip_changes.value = 'always_ask';
	}
});
</script>

<template>
	<div class="modal-overlay betslip_settings">
		<div class="modal-content betslip_settings">
			<a class="close-button betslip_settings" @click="closeModal()" style="cursor: pointer;">
				<object class="betslip_settings" v-html="svg_data.form_cross_betslip_settings"></object>
			</a>
			<!-- Title -->
			<div class="modal-header betslip_settings">
				<h2 class="betslip_settings">{{ $t('account.account_preferences.betslip_settings') }}</h2>
			</div>

			<!-- Options -->
			<div class="modal-body betslip_settings">
				<form class="betslip_settings">
					<div class="option betslip_settings">
						<label class="switch-label betslip_settings" @click="toggleBetslipChangesPreferences('accept_any_odds')">
							<input class="betslip_settings" :checked="betslip_changes == 'accept_any_odds'" type="checkbox">
							<span class="custom-switch betslip_settings"></span>
							<p class="betslip_settings">{{ $t('account.account_preferences.accept_any_odds') }}</p>
						</label>
					</div>
				</form>

				<form class="betslip_settings">
					<div class="option betslip_settings">
						<label class="switch-label betslip_settings" @click="toggleBetslipChangesPreferences('accept_higher_odds')">
							<input class="betslip_settings" :checked="betslip_changes == 'accept_higher_odds'" type="checkbox">
							<span class="custom-switch betslip_settings"></span>
							<p class="betslip_settings">{{ $t('account.account_preferences.accept_higher_odds') }}</p>
						</label>
					</div>
				</form>
			</div>

			<!-- Close Button -->
			<div class="modal-footer betslip_settings">
				<button class="close-modal-button betslip_settings" @click="closeModal()">{{$t('betslip.close')}}</button>
			</div>
		</div>
	</div>
  </template>

<style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

/* Modal Content */
.modal-content {
	background-color: #202833; /* Dark background */
	color: #fff; /* White text */
	padding: 20px;
	border-radius: 10px;
	text-align: center;
	position: relative;
	width: 400px;
	max-width: 80%;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 20px;
  color: #63A246;
  margin: 0;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
}

.close-button:hover {
  color: #63A246;
}

/* Modal Body */
.modal-body {
  margin-bottom: 20px;
}

.option {
  margin-bottom: 15px;
  text-align: left;
}

.switch-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

/* Custom Switch */
.custom-switch {
  width: 34px;
  height: 20px;
  background: #ccc;
  border-radius: 20px;
  position: relative;
  margin-right: 10px;
  transition: all 0.3s;
}

.custom-switch:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: all 0.3s;
}

input[type='checkbox']:checked + .custom-switch {
  background: #63A246;
}

input[type='checkbox']:checked + .custom-switch:before {
  transform: translateX(14px);
}

/* Modal Footer */
.modal-footer {
  margin-top: 10px;
}

.close-modal-button {
  background: #FFBB02;
  color: #202833;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.close-modal-button:hover {
  background: #FFBB02;
}

body.modal-open {
  overflow: hidden;
  padding-right: 0 !important; /* Override any padding changes */
}

html[dir="rtl"] .close-button {
	right: unset !important;
	left: 10px !important;
}
</style>
