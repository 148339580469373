<script setup>
/* eslint-disable */
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const stats_id = route.params.stats_id;

const api_key = process.env.VUE_APP_STATISTICS_API_KEY;
const base_url = process.env.VUE_APP_STATISTICS_URL;

const getSourceLink = () => {
	return base_url + '/stats?apikey=' + api_key +  '&statsid=' + stats_id;
};
</script>

<template>
	<div class="iframe-container">
		<iframe class="fullscreen-iframe" :src="getSourceLink()"></iframe>
	</div>
</template>

<style scoped>
.iframe-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh; /* Adjust height as needed */
	margin-top: 20px;
}

.fullscreen-iframe {
	max-width: 768px;
	width: 100%;
	height: 100%;
	border: none;
}
</style>
