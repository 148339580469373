<script setup>
/* eslint-disable */
import { watch, computed, onMounted, ref } from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useSocketStore } from '@/stores/socket';
import SingleBet from './SingleBet.vue';
import MultipleBets from './MultipleBets.vue';
import System from './SystemBet.vue';
import ReceiptBetslip from './ReceiptBetslip.vue';
import { useSharedBetslipsStore } from '@/stores/shared_betslips';
import { openModal } from "@kolirt/vue-modal";
import SharedBetslipError from '@/components/modals/SharedBetslipError.vue';

const betslipStore = BetSlip();
const socketStore = useSocketStore();

const shared_betslip_code = ref(null);
const button_disabled = ref(true);
const sharedBetslipStore = useSharedBetslipsStore();

socketStore.getSocket.on('fixtureOddInfoInsert', (data) => {
	betslipStore.addFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoUpdate', (data) => {
	betslipStore.updateFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoDelete', (data) => {
	betslipStore.deleteFixtureOddInfo(data);
})

const setSelectedTab = (tab) => {
	betslipStore.tab_setted = true;
	betslipStore.setSelectedTab(tab);
};

onMounted(() => {
	if (betslipStore.tab_setted == false) {
		if (betslipStore.countBetslipBets > 1) {
			betslipStore.setSelectedTab('multiple');
		}
	}

	betslipStore.socketJoinBets();
})

const isMultipleTabEnabled = computed(() => {
	if (betslipStore.countBetslipBets > 1) return true;
	return false;
})

const isSystemTabEnabled = computed(() => {
	if (betslipStore.countBetslipBets > 1) return true;
	return false;
})

const fetchSharedBetslip = () => {
	if (shared_betslip_code.value == null && shared_betslip_code.value == '') return

	sharedBetslipStore.fetchSharedBetslip(shared_betslip_code.value).then((response) => {
		betslipStore.resetBetslip();

		let bets = JSON.parse(response)
		let shared_id = shared_betslip_code.value

		let must_delete = false;

		for (let index in bets) {
			let bet = bets[index];

			betslipStore.toggleToBetslip({ odd_uuid: bet.bet_uuid, fixture_id: bet.fixture_id }, shared_id).catch(error => {
				if (!must_delete) {
					sharedBetslipStore.deleteSharedBetslip(shared_id, false);
					must_delete = true;
				}
			});
		}

		shared_betslip_code.value = null;

	}).catch((error) => {
		if (error.response.status == 429) return;
		openModal(SharedBetslipError, {errors: error.response, need_redirect: false}).catch(() =>{});
	})
}

watch(() => betslipStore.countBetslipBets, (newCount, oldCount) => {
	// Change tab to multiple if bets go from 1 to 2
	if (newCount > 1 && oldCount == 1) {
		if (betslipStore.selectedTab == 'singles') betslipStore.setSelectedTab('multiple');
	}

	// Change to single if bet goes to 1 from > 1
	if (newCount == 1 && oldCount > 1) {
		if (betslipStore.selectedTab == 'multiple') betslipStore.setSelectedTab('singles');
	}

	// Change tab to multiple if bets go to 2 from > 2
	if (newCount == 2 && oldCount > 2) {
		if (betslipStore.selectedTab == 'system') betslipStore.setSelectedTab('multiple');
	}
})

watch(() => shared_betslip_code.value, (newValue, oldValue) => {
	if (newValue != '' && newValue != null) button_disabled.value = false;
	else if (newValue == '' || newValue == null) button_disabled.value = true;
})

</script>
<template>
	<template v-if="betslipStore.betslip.placed">
		<ReceiptBetslip/>
	</template>
	<template v-else>
		<div class="bet_slip_wrap tab-content " :class="betslipStore.betSlipModel == 'betslip' ? 'active open' :''"   id="tab1">
			<!-- round -->
			<div class="filter">
				<ul>
					<li class="tab-item" @click="setSelectedTab('singles')" :class="{'active' :betslipStore.betslip.type  == 'singles'}" style="cursor: pointer;"><a >{{$t('betslip.singles')}}</a></li>
					<li class="tab-item" @click="setSelectedTab('multiple')" :class="{'active' :betslipStore.betslip.type  == 'multiple', 'disabled': !(betslipStore.countBetslipBets > 1)}" style="cursor: pointer;"><a >{{$t('betslip.multiples')}}</a></li>
					<li class="tab-item" @click="setSelectedTab('system')" :class="{'active' : betslipStore.betslip.type  == 'system', 'disabled': !(betslipStore.countBetslipBets > 1)}" style="cursor: pointer;"><a >{{$t('betslip.system')}}</a></li>
				</ul>
			</div>
			<template v-if="betslipStore.countBetslipBets > 0">
				<div class="betslip-inner_content single-tab-content" :class="betslipStore.betslip.type  == 'singles' ? 'active' :''">
					<SingleBet :type="'single'"/>
				</div>
				<div class="betslip-inner_content multiple-tab-content" :class="betslipStore.betslip.type  == 'multiple' ? 'active' :''">
					<MultipleBets/>
				</div>
				<div class="betslip-inner_content system-tab-content" :class="betslipStore.betslip.type  == 'system' ? 'active' :''" >
					<System/>
				</div>
			</template>

			<template v-else>
				<div class="bets_body" style="min-height: 100px;">
					<div class="bet-empty">
					<p>{{$t('betslip.you_havent_selected_any_bet')}} </p>
					<p>{{$t('betslip.you_can_add_bets_by_clicking_the_odds')}}</p>
					</div>
				</div>

				<div class="grid">
					<div class="text-field">
						<input type="text" class="form-input" v-model="shared_betslip_code" :placeholder="$t('general.type_shared_bet_code')">
						<a @click="shared_betslip_code = null"><i class="fa fa-times-circle clear-button" aria-hidden="true"></i></a>
					</div>
					<button class="share-btn" :class="{'btn-disabled': button_disabled}" type="button" @click="fetchSharedBetslip()">
						<i class="fa fa-plus" aria-hidden="true" style="margin-right: 2px;"></i>&nbsp;{{ $t('general.add_bets') }}
					</button>
				</div>
			</template>
		</div>
	</template>

</template>
<style scoped>
.disabled {
	pointer-events: none;
}

.grid {
	display: grid;
	grid-template-columns: 70% 20%;
	gap: 3%;
	padding: 10px 20px 20px 20px;
	align-items: center;
	justify-content: space-evenly;
}

.text-field {
	position: relative;
}

.clear-button {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translate(-50%, -50%);
	cursor: pointer;
	width: 14px;
	color: #8E9298;
}

html[dir="rtl"] .clear-button {
	left: 16px;
	right: unset;
}

.share-btn {
	background: #FFBB02;
	color: #202833;
	border: 1px solid transparent;
	height: 24px;
	transition: 0.3s ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	transition: 0.3s ease-in-out;
	padding: 0px 5px 3px 5px;
	width: 100px;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.share-btn:hover {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
}

.btn-disabled {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
	pointer-events: none;
}

.form-input {
	width: 100%;
	padding: 5px 40px 5px 15px;
	background-color: #363D47;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 100;
	color: #8E9298;
}

html[dir="rtl"] .form-input {
	padding: 5px 15px 5px 40px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
	.grid {
		grid-template-columns: 1fr;
	}

	.share-btn {
		width: 100%;
		margin-top: 5px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.grid {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}
}

@media screen and (min-width: 320px) and (max-width: 991px) {
	.share-btn {
		width: 100%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1150px) {
	.grid {
		grid-template-columns: 1fr;
	}
	.share-btn {
		width: 100%;
		margin-top: 5px;
	}
}
</style>