<script setup>
/* eslint-disable */
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { closeModal } from '@kolirt/vue-modal';
const route = useRoute();
const svg_data = commonSvgs;

const api_key = process.env.VUE_APP_STATISTICS_API_KEY;
const base_url = process.env.VUE_APP_STATISTICS_URL;

const props = defineProps({
	stats_id: {
		type: String,
		required: true
	}
})

const clickOutsideModal = (event) => {
	let modal = document.getElementById('mixani-statistics');
	if (modal && !modal.contains(event.target)) closeModal();
};

onMounted(() => {
	setTimeout(() => {
		document.addEventListener('click', clickOutsideModal);
	}, 500);
})

onUnmounted(() => {
	document.removeEventListener('click', clickOutsideModal);
});

const getSourceLink = () => {
	return base_url + '/stats?apikey=' + api_key +  '&statsid=' + props.stats_id;
};
</script>

<template>
	<div class="modal" >
		<div class="modal-content" id="mixani-statistics">
			<a class="close-button" @click="closeModal({})" style="cursor: pointer;">
				<object v-html="svg_data.form_cross"></object>
			</a>
			<div class="iframe-container">
				<iframe class="fullscreen-iframe" :src="getSourceLink()"></iframe>
			</div>
		</div>
	</div>

</template>

<style scoped>
.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
}

/* Modal Content */
.modal-content {
	background-color: #131C27; /* Dark background */
	color: #fff; /* White text */
	border-radius: 10px;
	text-align: center;
	position: relative;
	width: 768px;
	max-width: 80%;
	height: 100%;
	margin-top: 0px;
}

/* Close Button */
.close-button {
	position: absolute;
	top: 10px;
	right: 20px;
	color: #fff;
	font-size: 24px;
	cursor: pointer;
}

.iframe-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%; /* Adjust height as needed */
}

.fullscreen-iframe {
	max-width: 768px;
	width: 100%;
	height: 100%;
	border: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.iframe-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.modal-content {
	   max-width: 100%;
	   padding-left: 0px;
	   padding-right: 0px;
	   padding-bottom: 0px;
	}
}


</style>
