import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';
import _ from 'lodash';

export const useMessageStore = defineStore('message', {
	state: () => ({
		messages: [],
		message:[],
	}),
	getters: {
		unread_messages_length(state) {
			return _.filter(state.messages, { datetime_read_by_customer: null }).length;
		}
	},
	actions: {
		getMessageIndex() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/messages/index').then((response) => {
				this.messages = response.data.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		createMessage(payload) {
			const formData = new FormData();
			if (payload.file) {
				formData.append('file', payload.file);
			}
			formData.append('subject', payload.subject);
			formData.append('message', payload.message);
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/messages/message/create',formData,{ headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
				return Promise.resolve(response.data);

			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		getMessage(message_id) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/messages/message/'+ message_id).then((response) => {
				this.message = response.data.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		replyMessage(payload) {
			const formData = new FormData();
			if (payload.file) {
				formData.append('file', payload.file);
			}
			formData.append('message_id', payload.message_id);
			formData.append('reply_message', payload.reply_message);
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/messages/message/' + payload.message_id + '/reply', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
				this.message = response.data.data;
				return Promise.resolve(response);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		},
		deleteAttachment(payload) {
			return global.axios.delete(process.env.VUE_APP_API_URL + '/account/messages/message/'+ payload.message_id + '/attachment/' + payload.attachment_id + '/delete').then((response) => {
				this.message = response.data.data;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		downloadAttachment(payload) {
			return global.axios.get(payload.path, {responseType: 'blob'}).then((response) => {
				const link = document.createElement('a');
				link.href=URL.createObjectURL(response.data);
				link.setAttribute('download', payload.filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(response.data);
					return Promise.resolve();
			}).catch((error) => {
					return Promise.reject(error.response);
			});
		},
		reduceUnreadMessagesCount() {
			const customerStore = useCustomerStore();

			if (!customerStore.customer || customerStore.customer.unread_messages_count == 0) return 0;
			customerStore.customer.unread_messages_count -= 1;
		},
		deleteMessages(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/messages/delete', {
				message_ids: payload.message_ids,
			}).then((response) => {
				this.messages = this.messages.filter(message => !payload.message_ids.includes(message.id));
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		}
	}
})