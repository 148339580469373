<!-- eslint-disable -->
<script setup>
import { ref } from 'vue';
import { useCustomerStore } from '@/stores/customer';
import { useCurrenciesStore } from '@/stores/currencies';
import { useBonusStore } from '@/stores/bonus';
import { useLoginStore } from '@/stores/login';
import { useRouter } from 'vue-router';
import { openModal } from '@kolirt/vue-modal';
import headerIcons from '@/assets/svgs/Header.json';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { useMobileDashboardPrevious } from "@/stores/mobile_dashboard_previous_route"
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';

const router = useRouter();
const mobile_tab_active = ref(false);
const accordion_visibility_mobile = ref({ account_details: false, bank: false, history: false, player_protection: false});
const customerStore = useCustomerStore();
const currenciesStore = useCurrenciesStore();
const bonusStore = useBonusStore();
const loginStore = useLoginStore();
const { t } = useI18n();
const header_icons = headerIcons;
const svgs_data = commonSvgs;
const tab_active = ref('account');
const loading = ref(false);
const active_bonus = ref(null);
const refresh_balance = ref(false);
const active_bonus_id = ref(null);
const mobileDashboardStore = useMobileDashboardPrevious();
const is_country_ghana = process.env.VUE_APP_COUNTRY == 'GHANA';

const claimBonus = (bonus_id) => {
	loading.value = true;
	let payload = { bonus_id: bonus_id };

	if (!customerStore.hasVerifiedEmail) {
		openModal(VerificationEmail);
		loading.value = false;
		return;
	}

	bonusStore.claimManualBonus(payload).then(() => {
		bonusStore.fetchOffers();
		fireSwal('success', t('general.claim_bonus'));
		loading.value = false;
	}).catch((error) => {
		fireSwal('error', error.data.message);
		loading.value = false;
	});
}

const toggleAccordionMobile = (menu_slug) => {
	if (accordion_visibility_mobile.value[menu_slug] != true) accordion_visibility_mobile.value[menu_slug] = true;
	else accordion_visibility_mobile.value[menu_slug] = false;
}

const goToRoute = (route_name) => {

	if (!customerStore.hasVerifiedEmail) {
		showVerificationEmailModal();

		return;
	}

	router.push({ name: route_name });
	mobile_tab_active.value = false;
	mobileDashboardStore.setMobileDashboardPrevious(router.currentRoute.value)
}

const goToDeposit = (deposit_bonus) => {
	if (deposit_bonus) router.push({ name: 'bank-deposits', query: { bonus_id: deposit_bonus.id } });
	else router.push({ name: 'bank-deposits' })
}

const cancelBonus = (bonus_id) => {
	loading.value = true;
	let payload = { bonus_id: bonus_id };

	active_bonus_id.value = null;

	bonusStore.cancelCustomerBonus(payload.bonus_id).then(() => {
		fireSwal('success', t('general.cancel_bonus'));
		loading.value = false;
		bonusStore.fetchOffers();
	}).catch((error) => {
		fireSwal('error', error.data.message);
		loading.value = false;
	});
}

const cancelBonusVerification = (id) => {
	active_bonus_id.value = id
	confirmCancelSwal();
}

const logout = () => {
	loginStore.logout()
};

const toggleBalanceVisibility = () => {
	if (customerStore.balance_visibility == true) customerStore.balance_visibility = false;
	else customerStore.balance_visibility = true;
};

const refreshBalance = () => {
	refresh_balance.value = true

	customerStore.fetchBalance().then(() => {
		refresh_balance.value = false;
	}).catch((error) => {
		refresh_balance.value = false;
	})
}
const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

const confirmCancelSwal = () => {
	Swal.fire({
		text: t('general.cancel_bonus_text'),
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: t('general.yes'),
		cancelButtonText: t('general.no'),
	}).then((result) => {
		if (result.isConfirmed) {
			cancelBonus(active_bonus_id.value);
		}
	});
}
</script>
<template>

	<div class="account-mobile_dashboard">
		<div class="mobile-dash-main_area">
			<div class="user-amount-info-top">
				<div class="user-amount-info">
					<div class="user-amount-inner">
						<span>{{ $t('general.withdrawable') }}</span>
						<h6 v-if="customerStore.balance_visibility == true">{{ currenciesStore.format_currency(customerStore.getCustomer.balance.withdrawable, customerStore.getCustomer.currency.code) }}</h6>
						<h6 v-else>********</h6>
					</div>
					<div class="user-amount-inner">
						<span>{{ $t('general.bet_credits') }}</span>
						<h6 v-if="customerStore.balance_visibility == true">{{ currenciesStore.format_currency(customerStore.getCustomer.balance.bonus_balance, customerStore.getCustomer.currency.code) }}</h6>
						<h6 v-else>********</h6>
					</div>
					<div class="balance-container">
						<span class="mobile-only" v-if="customerStore.balance_visibility == true">
						<!-- <svg @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
							<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
							<circle cx="12" cy="12" r="3"></circle></svg> -->
							<svg @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="25.139" height="18.477" viewBox="0 0 25.139 18.477">
								<g id="Icon_akar-eye" data-name="Icon akar-eye" transform="translate(-0.819 -2.417)">
									<path id="Path_990" data-name="Path 990" d="M24.949,10.359a2.114,2.114,0,0,1,0,2.592c-1.864,2.433-6.335,7.443-11.556,7.443S3.7,15.384,1.837,12.951a2.112,2.112,0,0,1,0-2.592C3.7,7.926,8.172,2.917,13.393,2.917S23.085,7.926,24.949,10.359Z" transform="translate(0 0)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
									<path id="Path_991" data-name="Path 991" d="M12.752,9A3.751,3.751,0,1,1,9,5.25,3.751,3.751,0,0,1,12.752,9Z" transform="translate(4.392 2.654)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
								</g>
							</svg>
						</span>
						<span class="mobile-only" v-else>
							<!-- <svg  @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
								<g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(-0.793 2.207)">
									<path id="Path_266" data-name="Path 266" fill="none" stroke="#FFFFFF" d="M12.28,14.846A6.42,6.42,0,0,1,8.5,16.153c-4.455,0-7-5.077-7-5.077A11.717,11.717,0,0,1,4.72,7.307M7.164,6.152A5.819,5.819,0,0,1,8.5,6c4.455,0,7,5.077,7,5.077A11.738,11.738,0,0,1,14.125,13.1m-4.276-.679a1.907,1.907,0,1,1-2.7-2.691" transform="translate(0 -2.577)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
									<path id="Path_267" data-name="Path 267" d="M1.5,1.5l14,14" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
								</g>
							</svg> -->
							<svg @click="toggleBalanceVisibility()" xmlns="http://www.w3.org/2000/svg" width="22.694" height="17.398" viewBox="0 0 22.694 17.398">
								<g id="Icon_akar-eye" data-name="Icon akar-eye" transform="translate(-0.825 -2.417)">
									<path id="Path_992" data-name="Path 992" d="M0,0,21,16" transform="translate(1.893 3.417)" fill="none" stroke="#83878d" stroke-width="1"/>
									<path id="Path_990" data-name="Path 990" d="M22.56,9.6a1.9,1.9,0,0,1,0,2.329c-1.675,2.186-5.693,6.688-10.384,6.688s-8.709-4.5-10.384-6.688a1.9,1.9,0,0,1,0-2.329C3.466,7.418,7.485,2.917,12.176,2.917S20.885,7.418,22.56,9.6Z" transform="translate(0 0)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
									<path id="Path_991" data-name="Path 991" d="M12.752,9A3.751,3.751,0,1,1,9,5.25,3.751,3.751,0,0,1,12.752,9Z" transform="translate(3.175 1.768)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
								</g>
							</svg>
						</span>
					</div>
					<div class="balance-container">
						<a @click="refreshBalance" class="dropdown-eye-icon">
							<svg :class="{ 'rotate-svg': refresh_balance }" xmlns="http://www.w3.org/2000/svg" width="25.414" height="21.09" viewBox="0 0 25.414 21.09">
								<g id="Group_2134" data-name="Group 2134" transform="translate(-374.293 -68.206)">
									<g id="Icon_feather-refresh-ccw" data-name="Icon feather-refresh-ccw" transform="translate(374.417 67)">
									<path id="Path_986" data-name="Path 986" d="M.583,2.333V9.047H7.129M24.583,20.236V13.523H18.038" transform="translate(0 0.466)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
									<path id="Path_987" data-name="Path 987" d="M21.845,8.417A9.882,9.882,0,0,0,14.756,2,9.7,9.7,0,0,0,5.645,4.683L.583,9.529m24,4.446L19.522,18.82A9.7,9.7,0,0,1,10.411,21.5a9.882,9.882,0,0,1-7.089-6.419" transform="translate(0)" fill="none" stroke="#83878d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
									</g>
								</g>
							</svg>
					</a>
					</div>
				</div>
				<div class="user-amount-btn">
					<router-link to="/account/bank/deposits" class="btn btn-secondary">{{ $t('general.deposit') }}</router-link>
				</div>
			</div>

			<div class="user-dropdown-tabs offer-tab-active">
				<div class="tab">
					<button class="tablinks" :class="tab_active=='account' ? 'active' : ''" @click=" tab_active = 'account'">{{ $t('general.my_account') }}</button>
					<button class="tablinks" :class="tab_active=='offers' ? 'active' : ''" @click=" tab_active = 'offers'">{{ $t('general.my_offers') }}</button>
				</div>

				<!-- my account content area -->
				<div class="tab-content" :class="tab_active == 'account'? 'active': ''" >
					<div class="accordion">
						<div class="accordion-item" :class="accordion_visibility_mobile['account_details'] == true ? 'active' : ''">
							<div class="accordion-header" @click="toggleAccordionMobile('account_details')">
								<button class="active">
									<div class="header-left">
										<object v-html="commonSvgs.user_White_round_ico"></object>
									</div>
									<span>{{ $t('account.account_details.title') }}</span>
								</button>
								<div class="acoordion-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
										viewBox="0 0 11.414 6.414">
										<g id="Group_897" data-name="Group 897"
											transform="translate(10.707 0.707) rotate(90)">
											<line id="Line_7" data-name="Line 7" y1="5" x2="5"
												transform="translate(0 5)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
											<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
												stroke="#fff" stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>

								</div>
							</div>
							<div class="accordion-content">
								<div class="inner-tabing-list">
									<a @click="goToRoute('account-details')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details' ? 'active' : ''">{{ $t('account.account_details.title') }}</a>
									<a @click="goToRoute('account-details-preferences')" class="inner-tab-item" :class="router.currentRoute.value.name == 'account-details-preferences' ? 'active' : ''">{{ $t('account.account_preferences.title') }}</a>
									<a @click="goToRoute('account-details-password')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'account-details-password' ? 'active' : ''">{{ $t('account.account_password.title') }}</a>
									<a v-if="!is_country_ghana" @click="goToRoute('account-details-verification-process')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'account-details-verification-process' ? 'active' : ''">{{ $t('account.verification_process.title') }}</a>
								</div>

							</div>
						</div>

						<div class="accordion-item" :class="accordion_visibility_mobile['bank'] == true ? 'active' : ''">
							<div @click="toggleAccordionMobile('bank')" class="accordion-header">
								<button class="">
									<div class="header-left">
										<object v-html="commonSvgs.bank_White_ico"></object>
									</div>
									<span>{{ $t('general.bank') }}</span>
								</button>
								<div class="acoordion-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
										viewBox="0 0 11.414 6.414">
										<g id="Group_897" data-name="Group 897"
											transform="translate(10.707 0.707) rotate(90)">
											<line id="Line_7" data-name="Line 7" y1="5" x2="5"
												transform="translate(0 5)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
											<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
												stroke="#fff" stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>

								</div>
							</div>
							<div class="accordion-content">
								<div class="inner-tabing-list">
									<a @click="goToRoute('bank-deposits')" class="inner-tab-item" :class="router.currentRoute.value.path.startsWith('/account/bank/deposits') ? 'active' : ''">{{ $t('bank.deposit.deposit') }}</a>
									<a @click="goToRoute('bank-withdrawals')" class="inner-tab-item" :class="router.currentRoute.value.path.startsWith('/account/bank/withdrawals') ? 'active' : ''">{{ $t('bank.withdraw.withdraw') }}</a>
									<a @click="goToRoute('bank-pending-transactions')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'bank-pending-transaction' ? 'active' : ''">{{ $t('bank.pending.pending') }}</a>
								</div>

							</div>
						</div>

						<div class="accordion-item" :class="accordion_visibility_mobile['history'] == true ? 'active' : ''">
							<div class="accordion-header" @click="toggleAccordionMobile('history')">
								<button>
									<div class="header-left">
										<object v-html="commonSvgs.history_White_ico"></object>
									</div>
									<span>{{ $t('general.history') }}</span>
								</button>
								<div class="acoordion-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
										viewBox="0 0 11.414 6.414">
										<g id="Group_897" data-name="Group 897"
											transform="translate(10.707 0.707) rotate(90)">
											<line id="Line_7" data-name="Line 7" y1="5" x2="5"
												transform="translate(0 5)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
											<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
												stroke="#fff" stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>

								</div>
							</div>
							<div class="accordion-content">
								<div class="inner-tabing-list">
									<a @click="goToRoute('history-settled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-settled-bets' ? 'active' : ''">{{ $t('general.settled_bets') }}</a>
									<a @click="goToRoute('history-unsettled-bets')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-unsettled-bets' ? 'active' : ''">{{ $t('general.unsettled_bets') }}</a>
									<a @click="goToRoute('history-slots')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-slots' ? 'active' : ''">{{ $t('general.slots') }}</a>
									<a @click="goToRoute('history-live-casino')" class="inner-tab-item" :class="router.currentRoute.value.name == 'history-live-casino' ? 'active' : ''">{{ $t('general.live_casino') }}</a>
									<a @click="goToRoute('history-deposits')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'history-deposits' ? 'active' : ''">{{ $t('general.deposits') }}</a>
									<a @click="goToRoute('history-withdrawals')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'history-withdrawals' ? 'active' : ''">{{ $t('general.withdrawals') }}</a>
									<a @click="goToRoute('history-transactions')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'history-transactions' ? 'active' : ''">{{ $t('general.transactions') }}</a>
								</div>

							</div>
						</div>
						<div class="accordion-item" :class="accordion_visibility_mobile['player_protection'] == true ? 'active' : ''">
							<div class="accordion-header" @click="toggleAccordionMobile('player_protection')">
								<button>
									<div class="header-left">
										<object v-html="commonSvgs.player_White_ico"></object>
									</div>
									<span>{{ $t('account.customer_protection.customer_protection_title') }}</span>
								</button>
								<div class="acoordion-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
										viewBox="0 0 11.414 6.414">
										<g id="Group_897" data-name="Group 897"
											transform="translate(10.707 0.707) rotate(90)">
											<line id="Line_7" data-name="Line 7" y1="5" x2="5"
												transform="translate(0 5)" fill="none" stroke="#fff"
												stroke-linecap="round" stroke-width="1" />
											<line id="Line_8" data-name="Line 8" x2="5" y2="5" fill="none"
												stroke="#fff" stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>

								</div>
							</div>
							<div class="accordion-content">
								<div class="inner-tabing-list">
									<a @click="goToRoute('player-protection-login-limit')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/limits') ? 'active' : ''">{{ $t('account.customer_protection.limits') }}</a>
									<a @click="goToRoute('player-protection-cancel-withdraw-request')" class="inner-tab-item " :class="router.currentRoute.value.name == 'player-protection-cancel-withdraw-request' ? 'active' : ''">{{ $t('account.customer_protection.cancel_withdraw_request_title') }}</a>
									<a @click="goToRoute('player-protection-reality-check')" class="inner-tab-item" :class="router.currentRoute.value.name == 'player-protection-reality-check' ? 'active' : ''">{{ $t('account.customer_protection.reality_check_title') }}</a>
									<a @click="goToRoute('player-protection-time-out')" class="inner-tab-item" :class="router.currentRoute.value.path.includes('player-protection/time-limits') ? 'active' : ''">{{ $t('account.customer_protection.time_limits') }}</a>
									<a @click="goToRoute('player-protection-self-exclusion')" class="inner-tab-item last_item" :class="router.currentRoute.value.name == 'player-protection-self-exclusion' ? 'active' : ''">{{ $t('account.customer_protection.self_exclusion_title') }}</a>
								</div>

							</div>
						</div>


						<div class="accordion-item" :class="router.currentRoute.value.name == 'account-messages' ? 'active' : ''">
							<div class="accordion-header">
								<button @click="goToRoute('account-messages')" >
									<div class="header-left" v-if="customerStore.getCustomer">
										<span v-if="customerStore.getCustomer.unread_messages_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_messages_count }}</span>
										<object v-html="commonSvgs.message_White_ico"></object>
									</div>
									<span>{{ $t('message.messages') }}</span>
								</button>
							</div>
						</div>

						<div class="accordion-item" :class="router.currentRoute.value.name == 'bonus-active-bonuses' ? 'active' : ''">
							<div class="accordion-header">
								<button @click="goToRoute('bonus-active-bonuses')">
									<div class="header-left">
										<span v-if="customerStore.unclaimed_bonuses_length > 0" class="notification-num">{{ customerStore.unclaimed_bonuses_length }}</span>
										<object v-html="commonSvgs.gift_White_ico"></object>
									</div>
									<span>{{ $t('general.bonus') }}</span>
								</button >

							</div>
						</div>

						<!-- <div class="accordion-item" :class="router.currentRoute.value.name == 'account-notifications' ? 'active' : ''">
							<div class="accordion-header notification">
								<button @click="goToRoute('account-notifications')" >
									<div class="header-left" v-if="customerStore.getCustomer">
										<span v-if="customerStore.getCustomer.unread_notifications_count > 0" class="notification-num" style="right:5px">{{ customerStore.getCustomer.unread_notifications_count }}</span>
										<svg class="notification" id="_30-Gift" data-name="30-Gift" xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 28 23">
											<path d="M19.29 17.29L18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.9 0 1.34-1.08.71-1.71zM16 17H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zm-4 5c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z" fill="#878a90" stroke="#202833" stroke-width="0.25" class="color000000 svgShape">
											</path>
										</svg>
									</div>
									<span class="notification_span">{{ $t('account.account_preferences.notifications') }}</span>
								</button>
							</div>
						</div> -->

					</div>
				</div>
				<!-- end -->
				<!-- my offer content area -->
				<div class="tab-content" :class="tab_active=='offers'? 'active': ''">
					<div class="my-offer-outer">
						<div class="offers-accordion">
							<div class="offers-empty" v-if="(!customerStore.availableBonuses.length > 0 && !customerStore.activeBonuses.length > 0)">
								<p>{{ $t('general.no_available_bonus') }}</p>
							</div>

							<div class="offers-accordion-inner" v-else v-for="(available_bonus, index) in customerStore.availableBonuses" :key="index" :class="active_bonus==index+'index'? 'active':''" @click="active_bonus==index+'index'? active_bonus = '': active_bonus = index+'index'">
								<div class="offers-top-header">
									<h3>{{ available_bonus.name }}</h3>
									<span v-html="svgs_data.dropdown_icon"></span>
								</div>
								<div class="offers-tab-content">
									<div class="offers-tab-main">
										<div class="offer-content-inner">
											<h4>{{ $t('account.affiliate.product') }}:</h4>
											<p>{{ available_bonus.products }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.minimum_deposit_amount') }}:</h4>
											<p>{{ currenciesStore.format_currency(available_bonus.min_deposit_amount, customerStore.customer.currency.code) }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.maximum_bonus_amount') }}:</h4>
											<p>{{ currenciesStore.format_currency(available_bonus.max_bonus_amount, customerStore.customer.currency.code) }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.deposit_bonus_percentage') }}:</h4>
											<p>{{ available_bonus.bonus_deposit_percentage }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.rollover_factor_deposit') }}:</h4>
											<p>{{ available_bonus.rollover_factor_deposit }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.rollover_factor_bonus') }}:</h4>
											<p>{{ available_bonus.rollover_factor_bonus }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.expiration_days') }}:</h4>
											<p>{{ available_bonus.expiration_days }}</p>
										</div>
									</div>
									<div v-if="available_bonus.is_deposit_bonus" class="user-amount-btn">
										<a @click="goToDeposit(available_bonus)" class="btn btn-secondary">{{ $t('account.affiliate.deposit') }}</a>
									</div>
									<div class="user-amount-btn" v-else>
										<button type="button" class="btn btn-secondary" v-if="!loading" @click="claimBonus(available_bonus.id)" style="cursor: pointer;">{{ $t('general.claim') }}</button>
										<button class="btn btn-secondary" type="button" v-else><div class="loader-main"></div></button>
									</div>
								</div>
							</div>

							<div class="offers-accordion-inner" v-show="customerStore.activeBonuses.length > 0" v-for="(bonus, index) in customerStore.activeBonuses" :key="index" :class="active_bonus==index+'index'? 'active':''" @click="active_bonus==index+'index'? active_bonus = '': active_bonus = index+'index'">
								<div class="offers-top-header">
									<h3>{{bonus.name}}</h3>
									<span v-html="svgs_data.dropdown_icon"></span>
								</div>
								<div class="offers-top-header">
									<h3>{{ $t('general.progress') }}:</h3>
									<div class="main-progress-bar">
										<progress :value="bonus.stake_current * 100 / bonus.stake_target" max="100" class="main-progress-bar"></progress>
									</div>
								</div>
								<div class="offers-tab-content">
									<div class="offers-tab-main">
										<div class="offer-content-inner">
											<h4>{{ $t('general.bonus_description') }}:</h4>
											<p>{{ bonus.short_description }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.bonus_amount') }}:</h4>
											<p>{{ currenciesStore.format_currency(bonus.bonus_amount, customerStore.customer.currency.code) }}</p>
										</div>
										<div class="offer-content-inner">
											<h4>{{ $t('general.expires_at') }}:</h4>
											<p>{{ bonus.expires_at }}</p>
										</div>
									</div>
									<div class="user-amount-btn">
										<button type="button" class="btn btn-secondary" v-if="!loading" @click="cancelBonusVerification(bonus.id)" style="cursor: pointer;">{{ $t('general.cancel') }}</button>
										<button class="btn btn-secondary" type="button" v-else><div class="loader-main"></div></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end -->
			</div>
		</div>

		<div class="user-logout-btn">
			<a href="#" @click="logout">{{ $t('general.logout') }}</a>
		</div>
	</div>
</template>
<style scoped>
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-left-sidebar .left-sidebar-dash .dash-inner-tabs {
	right: unset;
	left: -200px !important;
}
html[dir="rtl"] .dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header .acoordion-icon {
	position: absolute;
	right: unset;
	top: 35%;
	transform: translateY(-50%);
	left: 0;
}
.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer {
	border-radius:0px 0px 5px 5px;
}
.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer {
	padding: 27px 45px 98px 45px;
	overflow-x: hidden;
	height: 100%;
	min-height: 100vh;
	max-height: 200vh;
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	.dashboard-main .dashboard-outer .dashboard-left-sidebar {
		padding: 42px 20px 0;
		position: relative;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 1;
		padding-bottom: 50px;
	}
	.dashboard-main .dashboard-outer .dashboard-left-sidebar:after {
		content: "";
		width: 1px;
		height: 100%;
		background: #4F545C;
		position: absolute;
		right: 0;
		top: 0;
	}
	.dashboard-main .dashboard-outer .dashboard-tab-content .tab-content-outer .dash-inner-tabs:after {
		content: "";
		width: 1px;
		height: 100%!important;
		background: #4F545C;
		position: absolute;
		right: 0;
		top: 0;
	}
}
.close-btn{
	margin-left: auto;
	padding-top: 3px;
}
html[dir="rtl"] .close-btn{
	margin-right: auto;
	margin-left:unset;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header button .notification {
	width: 36px!important;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .notification {
	margin-left: -3px !important;
}

.dashboard-main .dashboard-outer .dashboard-mobile-tab .accordion-outer .accordion-item .accordion-header button .notification_span {
	margin-left: -8px !important;

}

</style>

