<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { openModal } from '@kolirt/vue-modal';
import { useLanguagesStore } from '@/stores/languages';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useOddsStore } from '@/stores/odds';
import { useI18n } from 'vue-i18n';
import { EditBetSlip } from '@/stores/edit_betslip';
import { MyBets } from '@/stores/MyBets';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import EditBet from '@/components/modals/edit_bet/EditBet.vue';

const svg_data = ref(commonSvgs);
const router = useRouter();
const oddsStore = useOddsStore();
const editBetslipStore = EditBetSlip();
const languagesStore = useLanguagesStore();
const preSportsStore = usePreSportsStore();
const liveSportsStore = useLiveSportsStore();
const myBetsStore = MyBets();
const { t } = useI18n();
const single_stakes = ref('');

const removeFromBetslip = (bet) => {
	editBetslipStore.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id }, false, true)
}

const goToFixture = (bet) => {
	if (bet.odd_info.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: bet.odd_info.fixture_id } });
		return;
	}

	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.odd_info.sport_id, region_id: bet.odd_info.region_id, competition_id: bet.odd_info.competition_id, fixture_id: bet.odd_info.fixture_id } });
}

watch(single_stakes, (newValue, oldValue) => {
	for (const key in editBetslipStore.betslip.bets) {
		editBetslipStore.betslip.bets[key].stake = newValue
	}
});

const cancelEdit = () => {
	location.reload();
}

const addBets = () => {
	editBetslipStore.edit_mode_enabled = false;
	editBetslipStore.betSlipModel = '';
	editBetslipStore.is_betslip_opened = false
	document.body.classList.remove('betslip-show');
	editBetslipStore.my_bets_betslip_index = myBetsStore.getBetslipIndexById(editBetslipStore.betslip.old_betslip_id);
	openModal(EditBet, {is_done_editing: true}).then(() => {}).catch(() => { });
}

watch(()=> editBetslipStore.getAllBets, ()=>{
	editBetslipStore.validateSameFixtures();
})

onMounted(() => {
	editBetslipStore.validateSameFixtures();
})

</script>

<template>
	<div class="betslip-top-area">
		<div class="betslip-beting-area">
			<!-- game -->
			<div class="inner-tab-content">
				<div class="bets_body" v-for="bet in editBetslipStore.extraBets" :key="bet.odd_info.uuid">
					<div class="over_row" :style="bet.has_error ? 'display: grid; grid-template-columns: auto 1fr auto;' : '' " :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
						<div class="circle" v-if="bet.has_error">
							<img v-if="bet.has_error" src="/assets/images/pink_circle_img.png" alt="icon">
						</div>
						<div class="left">
							<div class="top_content" :style="!bet.odd_info.live ? '' : ''">
								<div class="live-badge" v-if="bet.odd_info.live" v-html="svg_data.live_badge"></div>
								<div class="over">
									<p>{{ bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</p>
								</div>
							</div>
							<div class="subtitle">
								<p>{{ bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }}</p>
							</div>
						</div>
						<div class="right ">
							<div class="value" :class="{'betup': bet.odd_info.price > bet.price, 'betdown': bet.odd_info.price < bet.price}">
								<p>{{ bet.odd_info.sp ? bet.odd_info.placeholder : oddsStore.oddsFormat(bet.odd_info.price) }}</p>
							</div>
							<span class="seprater"></span>
							<div class="clear-ico betslip-button" @click="removeFromBetslip(bet)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.741" viewBox="0 0 12 14.741">
									<g id="Group_1850" data-name="Group 1850" transform="translate(0.5 0.5)">
										<g id="delete" transform="translate(0)">
										<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
										<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
											<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
											<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
										</g>
										<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
											<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
											<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										</g>
										</g>
									</g>
								</svg>
							</div>
						</div>
					</div>
					<div class="bottom_row">

						<div class="right">
							<div class="full_detail" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
								<div class="icon">
									<img :src="preSportsStore.preSportWhiteIcon(bet.odd_info.sport_id)" alt="card-image">
								</div>
								<div class="value" @click="goToFixture(bet)">
									<div class="title_top">
										<p>{{ bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}</p>
									</div>

									<div v-if="liveSportsStore.hasOneParticipant(bet.odd_info.sport_id) || bet.odd_info.fixture_outright" class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
									</div>

									<div v-else class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="bets_body_bottom">
		<div class="row_1">
			<template v-if="editBetslipStore.countBetslipBets == 50">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bet_limit_exceeded') }}</p>
					</div>
				</div>
			</template>
			<template v-if="editBetslipStore.same_fixture_error_message">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bets_can_not_combined') }}</p>
					</div>
				</div>
			</template>
			<div class="bottom-multi-btn">
				<div class="share-grid">
						<button class="share-btn btn-small" type="button" @click="cancelEdit()">
							{{ t('edit_betslip.cancel_edit') }}
						</button>
				</div>
				<a class="btn-small btn-secondary betslip-button" @click="addBets()" :class="{ 'disabled-button': editBetslipStore.extraBets.length == 0 || editBetslipStore.same_fixture_error_message }">
					<i class="fa fa-plus" aria-hidden="true" style="margin-right: 2px;"></i>
					{{ t('edit_betslip.add_bets') }}
				</a>
			</div>
		</div>
	</div>

</template>
<style scoped>
.lds-ring {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 2px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.eventime_mobal_content .bets_body_bottom .row .right_content_85 input {
	width: 75px;
}


.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D !important;
	color: #4E545D;
}
.loader-main {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
}

.register-input-check {
	padding: 4px 0px 0;
	display: flex;
	align-items: flex-start;
	gap: 8px;
}

.register-input-check input[type=checkbox] {
	-webkit-appearance: checkbox !important;
	-moz-appearance: checkbox !important;
	appearance: checkbox !important;
	margin-top: 4px;
}

.check-dash {
	position: absolute;
	opacity: 0;
}

.check-dash + label {
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	gap: 3%;
	width: 100%;
}

.register-input-check p {
	color: #fff;
	font-size: 12px;
	text-align: start;
	cursor: pointer;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.register-input-check .check-dash + label:before {
	content: "";
	margin-right: 0px;
	display: inline-block;
	vertical-align: text-top;
	width: 16px;
	height: 16px;
	background: transparent;
	outline: 1px solid #878A90;
	border-radius: 3px;
	margin-top: 1px;
	cursor: pointer;
}
.register-input-check .check-dash:checked + label:after {
	content: "";
	position: absolute;
	left: 6px;
	top: 4px;
	display: inline-block;
	transform: rotate(45deg);
	height: 9px;
	width: 4px;
	border-bottom: 1px solid #63A246;
	border-right: 1px solid #63A246;
}

html[dir="rtl"] .register-input-check .check-dash:checked + label:after {
	right: 6px;
	left: unset;
}

.btn-max {
	padding: 4px !important;
}

.eventime_mobal_content .target-all .row .stake-input input {
	width: 90px !important;
}
@media screen and (min-width: 768px) {

	.eventime_mobal_content .target-all .row .stake-input {
		max-width: 150px !important;
		width: 140px!important;
	}
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
	.eventime_mobal_content .betslip-inner_content .inner-tab-content {
		max-height: 230px;
	}
	.eventime_mobal_content {
		width: 35% !important;
	}
}

@media screen and (min-width: 991px) and (max-height: 750px) {
	.eventime_mobal_content .betslip-inner_content .inner-tab-content {
        max-height: 245px;
    }
}

</style>