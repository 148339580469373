import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';

export const useNotificationStore = defineStore('notification', {
	state: () => ({
		notifications: [],
		unread_notifications: [],
		selected_notification_id: null,
		notification_loading: false,
		unread_notification_loading: false
	}),
	getters: {
		getNotifications(state){
			return state.notifications
		},
		getUnreadNotifications(state){
			return state.unread_notifications
		},
	},
	actions: {
		// Fetch - Customer Unread Notifications
		fetchCustomerUnreadNotifications(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/notifications/unread', { params: { page: payload.page } }).then((response) => {
				const customerStore = useCustomerStore();

				this.unread_notifications = response.data
				customerStore.customer.unread_notifications_count = response.data.unread_notifications_count;

				return Promise.resolve(response);
			}).catch((error) => {

				return Promise.reject(error.response.data);
			})
		},
		// Fetch - Customer Notifications
		fetchCustomerNotifications(payload) {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/notifications', { params: { page: payload.page } }).then((response) => {
				this.notifications = response.data

				return Promise.resolve(response);
			}).catch((error) => {

				return Promise.reject(error.response.data);
			})
		},
		// Customer Notifications - Mark as read
		updateCustomerNotification(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/notifications/' + payload.id + '/update', payload).then((response) => {

				return Promise.resolve(response);
			}).catch((error) => {

				return Promise.reject(error.response.data);
			})
		},
		deleteNotification(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/notifications/delete', {
				notification_ids: payload.notification_ids,
			}).then((response) => {
				this.fetchCustomerNotifications({ page: 1 });
				this.fetchCustomerUnreadNotifications({ page: 1 });
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			});
		}
	}
})