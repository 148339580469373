import { defineStore } from 'pinia';

export const useNavbarStore = defineStore('navbar', {
	state: () => ({
		user_dropdown_opened: false,
		settings_dropdown: false,
		search_dropdown: false,
		mobile_menu: false,
		notifications_dropdown: false,
		notifications_dropdownMobile: false,
	}),
	getters: {
		isUserDropdownOpened(state) {
			return state.user_dropdown_opened
		},
		isSettingsDropdownOpened(state) {
			return state.settings_dropdown
		},
		isSearchDropdownOpened(state) {
			return state.search_dropdown
		},
		isNotificationsDropdownOpened(state) {
			return state.notifications_dropdown
		}
	},
	actions: {
		closeAll() {
			this.user_dropdown_opened = false
			this.settings_dropdown = false
			this.search_dropdown = false
			this.notifications_dropdown = false
		}
	}
})