<script setup>
/* eslint-disable */
import { ref, computed } from 'vue'
import { useMissionsStore } from '@/stores/missions';
import { useI18n } from 'vue-i18n';
import { closeModal } from '@kolirt/vue-modal'
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import Loader from '@/components/Common/Loader.vue';
import { onMounted, onBeforeMount } from 'vue';
import _ from "lodash";
import { useRouter } from 'vue-router';

const missionsStore = useMissionsStore();
const { t } = useI18n();
const router = useRouter();

const svg_data = commonSvgs;
const loading = ref(true);

const props = defineProps({
	mission_id: Number
});

const selected_mission = ref(props.mission || null);

onMounted(() => {
	if (props.mission_id) {
		selected_mission.value = _.find(missionsStore.active_missions, { id: props.mission_id }) || null;
		if (selected_mission.value) {
			missionsStore.checkSportbookMission(selected_mission.value.id).then(() => {
				loading.value = false;
			});
		} else {
			loading.value = false;
		}
	}
});

const handleClose = () => {
	missionsStore.removeMissionFromActive(selected_mission.value.id);
	closeModal(true);
};

const goToRoute = (name) => {
	router.push({ name: name });
	closeModal();
}

</script>
<template>
	<div class="card">
		<Loader v-if="!selected_mission || loading" />
		<template v-else>
			<div class="title">
				<img src="/assets/white_sport_icons/Archery.svg" width="28" height="28" alt="">
				<h4>{{ selected_mission.name }}</h4>
				<object v-html="svg_data.form_cross" @click="handleClose" style="cursor: pointer;"></object>
			</div>
			<div class="content">
				<h5>{{ t('missions.congratulations') }}</h5>
				<p>{{ selected_mission.mission_type == 'both' && (missionsStore.mission_check && missionsStore.mission_check.progress < 100)? t('missions.steps_completed_for_sportsbook') : t('missions.mission_completed') }}</p>
				<div v-if="selected_mission.mission_type == 'casino'" class="progressbar-container">
					<div class="progress">100%</div>
				</div>
			</div>
			<div class="footer">
				<template v-if="selected_mission.mission_type == 'casino'">
					<button v-if="selected_mission.rewards && selected_mission.rewards.freespin_quantity" class="btn-small btn-primary" @click="goToRoute('bonus-available-bonuses')" >{{ t('general.activate_mission_freespin') }}</button>
				</template>
				<button class="btn-small btn-secondary" @click="handleClose">{{ t('account.register.close') }}</button>
			</div>
		</template>
	</div>
</template>
<style scoped>
	.card {
		background-color: #131C27;
		border-radius: 10px;
		padding: 20px;
		width: 25%;
		height: 370px;
	}
	.title {
		display: grid;
		grid-template-columns: 28px 1fr 28px;
		gap: 10px;
		align-items: center;
		border-bottom: 1px solid #4E545D;
		padding-bottom: 6px;
	}
	.title h4 {
		color: #ffffff;
		text-wrap: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.content {
		margin-top: 25px;
		padding-bottom: 20px;
		border-bottom: 1px solid #4E545D;
	}
	.content h5 {
		color: #ffffff;
		margin-bottom: 10px;
	}
	.content p {
		color: #ffffff;
		font-size: 15px;
	}
	.progressbar-container {
		background-color: #2D3745;
		width: 100%;
		border-radius: 15px;
		margin-bottom: 20px;
		margin-top: 10px;
	}
	.progress {
		color: white;
		text-align: right;
		font-size: 15px;
		border-radius: 15px;
		padding-right: 10px;
		width: 100%;
		background-color: #63A246;
	}
	.warning {
		display: grid;
		grid-template-columns: 16px 1fr;
		gap: 10px;
		align-items: center;
		padding-top: 10px;
	}
	.warning p {
		font-size: 11px;
	}

	.footer {
		margin-top: 30px;
		display: flex;
		justify-content: end;
	}

	@media screen and (min-width: 320px) and (max-width: 374px) {
		.card {
			width: 100%;
			height: 400px;
		}
	}

	@media screen and (min-width: 375px) and (max-width: 600px) {
		.card {
			width: 100%;
		}
	}

	@media screen and (min-width: 601px) and (max-width: 768px) {
		.card {
			width: 85%;
		}
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		.card {
			width: 85%;
		}
	}

	@media screen and (min-width: 1025px) and (max-width: 1280px) {
		.card {
			width: 50%;
		}
	}

	@media screen and (min-width: 1281px) and (max-width: 1700px) {
		.card {
			width: 35%;
		}
	}

	.btn-primary {
		margin-right: 10px;
		width: auto;
	}
</style>