<script setup>
import { openModal } from '@kolirt/vue-modal';
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import Hls from 'hls.js';
import { useBestlineVideoStore } from '@/stores/bestline_video';
import { useCustomerStore } from "@/stores/customer";
import Login from '@/components/modals/Login.vue';
import { useVirtualCourtStore } from '@/stores/virtual_court';

const bestlineVideoStore = useBestlineVideoStore();
const customerStore = useCustomerStore();
const virtualCourtStore = useVirtualCourtStore();

const stream_url = ref(null);
const is_loading = ref(false);
const video_player = ref(null);
const hls = ref(null);

const showLoginModal = () => {
	openModal(Login).catch(() => { });
};

const loadVideo = () => {
	is_loading.value = true;

	if (virtualCourtStore.fixture_in_queue?.external_id) {
		let video = bestlineVideoStore.videoEvents.find(event => event.id === virtualCourtStore.fixture_in_queue?.external_id);
		if (video) {
			bestlineVideoStore.fetchVideoLink({ event_id: video.event_id }).then(link => {
				stream_url.value = link;
				is_loading.value = false;
			}).catch(error => {
				console.error(error);
				is_loading.value = false;
			});
		}
	}
};

const initializePlayer = () => {
	nextTick(() => {
		if (!video_player.value) {
			console.error('Video element not available');
			return;
		}

		if (Hls.isSupported()) {
			hls.value = new Hls({
				debug: false
			});

			hls.value.loadSource(stream_url.value);
			hls.value.attachMedia(video_player.value);

			hls.value.on(Hls.Events.MEDIA_ATTACHED, () => {
				video_player.value.muted = true;
				video_player.value.play().catch(e => console.error('Error playing video:', e));
				is_loading.value = false;
			});

			hls.value.on(Hls.Events.ERROR, (event, data) => {
				if (data.fatal) {
					switch (data.type) {
						case Hls.ErrorTypes.NETWORK_ERROR:
							console.error('fatal network error encountered, try to recover');
							hls.value.startLoad();
							break;
						case Hls.ErrorTypes.MEDIA_ERROR:
							console.error('fatal media error encountered, try to recover');
							hls.value.recoverMediaError();
							break;
						default:
							console.error('Unrecoverable error', data.type);
							hls.value.destroy();
							break;
					}
				}
			});
		} else if (video_player.value.canPlayType('application/vnd.apple.mpegurl')) {
			// Native support for HLS
			video_player.value.src = stream_url.value;
			video_player.value.play().catch(e => console.error('Error playing video:', e));
			is_loading.value = false;
		}
	});
};

watch(stream_url, (new_url, old_url) => {
	if (new_url != old_url) {
		initializePlayer();
	}
});

watch(virtualCourtStore.fixture_in_queue, (new_value, old_value) => {
	if (bestlineVideoStore.isEnabled == true && customerStore.isLoggedIn == true && new_value != null && old_value != null && new_value.id != old_value.id) {
		loadVideo();
	}
});

watch(() => virtualCourtStore.fixture_in_queue, (new_value, old_value) => {
	if (bestlineVideoStore.isEnabled == true && customerStore.isLoggedIn == true && new_value != null && old_value != null && new_value.id != old_value.id) {
		loadVideo();
	}
})

onMounted(() => {
	if (bestlineVideoStore.isEnabled == true && customerStore.isLoggedIn == true) {
		loadVideo();
	}
});

onBeforeUnmount(() => {
	if (hls.value) {
		hls.value.destroy();
	}
});
</script>
<template>
	<div class="event-field-container">
		<div v-if="virtualCourtStore.fixture_in_queue != null && virtualCourtStore.fixture_in_queue['participants'] && virtualCourtStore.fixture_in_queue['participants'].length >= 2"
			class="scoreboard">
			<div class="team text-align-right">{{ virtualCourtStore.fixture_in_queue.participants[0] }}</div>
			<div class="score-section">
				<template v-if="virtualCourtStore.fixture_in_queue.statistics && virtualCourtStore.fixture_in_queue.sport_id !== 2">
					<div class="score text-align-right pr-5px">{{ virtualCourtStore.fixture_in_queue.statistics.p1_score }}</div>
					<div class="score text-align-left pl-5px">{{ virtualCourtStore.fixture_in_queue.statistics.p2_score }}</div>
				</template>
				<template v-else-if="virtualCourtStore.fixture_in_queue.statistics">
					<div class="score text-align-right pr-5px">{{ virtualCourtStore.fixture_in_queue.statistics.p1_points }}</div>
					<div class="score text-align-left pl-5px">{{ virtualCourtStore.fixture_in_queue.statistics.p2_points
						}}</div>
				</template>
			</div>
			<div class="team text-align-left">{{ virtualCourtStore.fixture_in_queue.participants[1] }}</div>
		</div>

		<!-- Container for login button -->
		<div v-if="!customerStore.isLoggedIn" class="login-container">
			<a @click="showLoginModal()" class="btn-small ellipsis">{{ $t('bestline.login_to_watch') }}</a>
		</div>

		<div v-if="is_loading" class="video-container">
			<div class="loading-overlay">
				<div class="loader-main"></div>
			</div>
		</div>

		<!-- Error message when stream link is missing -->
		<div v-if="customerStore.isLoggedIn && !stream_url && !is_loading" class="error-container">
			<p>{{ $t('bestline.stream_url_is_missing') }}</p>
		</div>

		<!-- Container for Video.js -->
		<div v-else-if="customerStore.isLoggedIn && stream_url && !is_loading" class="video-container">
			<video ref="video_player" class="video-js vjs-default-skin video-player" controls>
				<source :src="stream_url" type="application/x-mpegURL">
				{{ $t('bestline.you_browser_does_not_support_video') }}
			</video>
		</div>
	</div>
</template>
<style scoped>
.login-container {
	background-color: black;
	width: auto;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	max-width: 150px;
	cursor: pointer;
}

.video-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	overflow: hidden;
}

.video-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.event-field-container {
	padding: 10px;
	background: var(--background-dark);
	border-bottom: 1px solid #474747;
	margin-bottom: 5px;
}

.loader-main {
	width: 30px;
	height: 30px;
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

.error-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	color: white;
	text-align: center;
	font-size: 14px;
	top: 0;
	left: 0;
	height: 100%;
}

.error-container p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.scoreboard {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	font-family: Arial, sans-serif;
	padding: 5px 20px;
	margin-bottom: 10px;
	border-radius: 5px;
	border-bottom: 1px solid #474747;
}

.team {
	padding: 0 10px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 3;
	-webkit-line-clamp: 3;
	overflow: hidden;
	word-break: break-word;
	word-wrap: break-word;
	line-height: 16px;
	max-height: calc(16px* 3);
	color: #e4e4e4;
	font-weight: 700;
	font-size: 13px;
}

.score-section {
	display: flex;
	justify-content: center;
	align-items: center;
}

.score {
	font-size: 19px;
	font-weight: bold;
	min-width: 30px;
	color: #63A246;
	font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Segoe UI, Tahoma, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Open Sans, sans-serif;
}


.text-align-right {
	text-align: right;
}

.text-align-left {
	text-align: left;
}

.pr-5px {
	padding-right: 5px;
}

.pl-5px {
	padding-left: 5px;
}

.pr-10px {
	padding-right: 10px;
}

.pl-10px {
	padding-left: 10px;
}

.button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 5px;
	margin-bottom: 10px;
}

.play-fill {
	fill: #686767;
}

.border {
	fill: none;
	stroke: #686767;
	stroke-width: 1;
}

.video-icon {
	width: 25px;
	height: 17px;
}

.field-icon-container:hover .play-fill {
	fill: #F2F2F7;
}

.field-icon-container:hover .border {
	stroke: #F2F2F7;
}

.video-icon-selected .play-fill {
	fill: #F2F2F7;
}

.video-icon-selected .border {
	stroke: #F2F2F7;
}
</style>