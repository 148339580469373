<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { openModal } from "@kolirt/vue-modal";
import Statistics from '@/components/modals/Statistics.vue'

const router = useRouter();

const props = defineProps({
	stats_id: {
		required: true,
	}
})

const openStatistics = () => {
	openModal(Statistics, {stats_id: props.stats_id}).catch(() => {});

	// const { href } = router.resolve({ name: 'statistics', params: { stats_id: props.stats_id } });
	// 	window.open(href, '_blank');
};

const isStatsEnabled = computed(() => {
	if (!process.env.VUE_APP_STATISTICS_URL) return false;
	if (!process.env.VUE_APP_STATISTICS_API_KEY) return false;
	if (!props.stats_id) return false;

	return true;
});

</script>

<template>
	<div v-if="isStatsEnabled" class="statistics-button" :class="{'pre-stats': router.currentRoute.value.name == 'pre-sports-fixturebook'}" @click="openStatistics">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.8-7.2-16-16-16H16C7.2 64 0 71.2 0 80v336c0 17.7 14.3 32 32 32h464c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/></svg>
	</div>
</template>

<style scoped>

.statistics-button {
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 0px;
	color: #fff;
	height: 20px;
	width: 20px;
}

.statistics-button {
	height: 16px;
	width: 16px;
}
.pre-stats {
	cursor: pointer;
	position: unset;
	margin-left: 8px;
}

</style>