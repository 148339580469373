<script setup>
import { ref, onMounted, watch } from "vue";
import { useMenuStore } from "@/stores/menus";
import { useInitializeStore } from '@/stores/initialize';
import Cookie from '../src/components/modals/Cookie.vue';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useFooterSectionStore } from '@/stores/footer_sections';
import { useMissionsStore } from '@/stores/missions';
import { useLanguagesStore } from "./stores/languages";
import { useCustomerStore } from "./stores/customer";
import { useRouter } from "vue-router";
import { openModal } from "@kolirt/vue-modal";
import SportsbookMissions from "./components/modals/missions/SportsbookMissions.vue";
import CasinoMissionCompletion from "@/components/modals/missions/CasinoMissionCompletion.vue";
import _ from "lodash";

const initializeStore = useInitializeStore();
const menuStore = useMenuStore();
const footerSectionStore = useFooterSectionStore();
const device_type = ref(menuStore.isMobile == true ? 'mobile' : 'desktop')
const missionsStore = useMissionsStore();
const languagesStore = useLanguagesStore();
const customerStore = useCustomerStore();
const router = useRouter();
const mission_check_interval = ref(null);

watch(() => device_type.value, (newValue) => {
	if (newValue) {
		menuStore.fetchMenus(device_type.value)
	}
})

watch(() => missionsStore.active_missions, (newValue) => {
	if (newValue.length) {
		startMissionChecking();
	}
})

const checkMissionsPeriodically = () => {
	if (missionsStore.active_missions == 0) return;
	missionsStore.checkCasinoMissionsCompletion().then(response => {
		Object.keys(response).forEach(mission_id => {
			const mission_status = response[mission_id];
			if (mission_status.success) {
				const mission = _.find(missionsStore.active_missions, { id: Number(mission_id) });
				if (mission && !mission.completed) {
					mission.completed = true;
					openModal(CasinoMissionCompletion, { mission_id: mission.id }).then(() => {
					}).catch(() => {});
				}
			}
		});
	}).catch(error => {
		console.error("Error checking missions:", error);
	});
};

const startMissionChecking = () => {
	if (!mission_check_interval.value) {
		checkMissionsPeriodically();
		mission_check_interval.value = setInterval(checkMissionsPeriodically, 20000);
	}
};

onMounted(() => {
	usePreSportsStore().fetchPreSports();
	if (customerStore.isLoggedIn) {
		missionsStore.fetchCustomerMissions().then(() => {
			if (router.currentRoute.value.name == 'missions-popup') openModal(SportsbookMissions, {mission_type: 'all'}).catch(() => {});
			startMissionChecking()
		});
	} else {
		missionsStore.fetchMissions(languagesStore.selected_language.id).then(() => {
			if (router.currentRoute.value.name == 'missions-popup') openModal(SportsbookMissions, {mission_type: 'all'}).catch(() => {});
		});
	}

	initializeStore.addGeneralPixel();
	window.addEventListener("resize", () => {
		initializeStore.updateScreenWidth();
	});

	footerSectionStore.fetchFooterSections();
})


</script>
<template>
	<RouterView />
	<ModalTarget/>
	<Cookie />
</template>